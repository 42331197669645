<template>
  <video
    v-if="enjoyModalModel === 'vesta-sw'"
    ref="loading"
    src="@/assets/img/enjoy/vesta-cross.mp4"
    autoplay
    muted
    playsinline
  ></video>
  <video
    v-else
    ref="loading"
    src="@/assets/img/enjoy/vesta.mp4"
    autoplay
    muted
    playsinline
  ></video>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "EnjoyScreenLoading",
  computed: {
    ...mapState(["enjoyModalModel"]),
  },
  mounted() {
    this.$refs.loading.addEventListener("ended", this.onEnded);
  },
  methods: {
    onEnded() {
      this.$emit("change", "yandex");
    },
  },
};
</script>

<style lang="scss" scoped>
video {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: 50% 50%;
}
</style>
