export const RESOURCES = {
  background: {
    light: {
      src: require("@/assets/img/cinema/light.webp"),
      srcMobile: require("@/assets/img/cinema/mobile/light.webp"),
    },
  },
};

export const VIDEO_LIST = [
  {
    src: "/static/demo/video/1.mp4",
    preview: "/static/demo/thumb/1.jpg",
    title: "ОБЗОР НОВОЙ LADA VESTA SW CROSS",
  },
  // {
  //   src: "/static/demo/video/2.mp4",
  //   preview: "/static/demo/thumb/2.jpg",
  //   title: "ОБЗОР НОВОЙ LADA VESTA SE",
  // },
  // {
  //   src: "/static/demo/video/4.mp4",
  //   preview: "/static/demo/thumb/1.jpg",
  //   title: "ОБЗОР НОВОЙ LADA VESTA SW CROSS",
  // },
  // {
  //   src: "/static/demo/video/3.mp4",
  //   preview: "/static/demo/thumb/1.jpg",
  //   title: "ОБЗОР НОВОЙ LADA VESTA SW CROSS",
  // },
  // {
  //   src: "/static/demo/video/2.mp4",
  //   preview: "/static/demo/thumb/2.jpg",
  //   title: "ОБЗОР НОВОЙ LADA VESTA SW CROSS",
  // },
  // {
  //   src: "/static/demo/video/1.mp4",
  //   preview: "/static/demo/thumb/3.jpg",
  //   title: "ОБЗОР НОВОЙ LADA VESTA SW CROSS",
  // },
];
