<template>
  <section v-if="current === 'loading'">
    <EnjoyLoading @change="onChange" />
  </section>
  <section v-else-if="current === 'yandex'">
    <img class="yandex" src="@/assets/img/enjoy/4.webp" alt="" />
  </section>
  <EnjoyMain v-else-if="current === 'main'" />
  <section v-else-if="current === 'location'">
    <img src="@/assets/img/enjoy/location-light.webp" v-if="whiteThemeEnjoy" />
    <img src="@/assets/img/enjoy/location-dark.webp" v-else />
    <img src="@/assets/img/enjoy/nav-location.webp" class="nav-location" />
  </section>
  <EnjoyMusic v-else-if="current === 'music'" />
  <EnjoyAlice v-else-if="current === 'alice'" />
  <EnjoySettings v-else-if="current === 'settings'" @change="onChange" />
  <EnjoySettingsSound
    v-else-if="current === 'settings-sound'"
    @change="onChange"
  />
  <EnjoySettingsAuto
    v-else-if="current === 'settings-auto'"
    @change="onChange"
  />
  <EnjoyMenu v-else-if="current === 'menu'" @change="onChange" />
  <EnjoyPhone v-else-if="current === 'phone'" @change="onChange" />
</template>

<script>
import EnjoyLoading from "@/components/EnjoyScreenLoading.vue";
import EnjoyMain from "./Enjoy/EnjoyMain.vue";
import EnjoyMusic from "./Enjoy/EnjoyMusic.vue";
import EnjoySettings from "./Enjoy/EnjoySettings.vue";
import EnjoySettingsSound from "./Enjoy/EnjoySettingsSound.vue";
import EnjoySettingsAuto from "./Enjoy/EnjoySettingsAuto.vue";
import EnjoyMenu from "./Enjoy/EnjoyMenu.vue";
import EnjoyPhone from "./Enjoy/EnjoyPhone.vue";
import EnjoyAlice from "./Enjoy/EnjoyAlice.vue";
import { mapState } from "vuex";

export default {
  name: "EnjoyScreens",
  components: {
    EnjoyLoading,
    EnjoyMain,
    EnjoyMusic,
    EnjoySettings,
    EnjoySettingsSound,
    EnjoySettingsAuto,
    EnjoyMenu,
    EnjoyPhone,
    EnjoyAlice,
  },
  props: {
    current: String,
  },
  computed: {
    ...mapState(["whiteThemeEnjoy"]),
  },
  emits: ["change"],
  methods: {
    onChange(value) {
      this.$emit("change", value);

      // Если заставка яндекса, через 4 секунды переключаем на главный экран
      if (value === "yandex") {
        setTimeout(() => {
          this.$emit("change", "main");
        }, 4000);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
section {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  > img,
  > video {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: 50% 50%;
  }
}
.yandex {
  animation: fade 4s both;
}
.nav-location {
  position: absolute;
  bottom: 0%;
  width: 100%;
  left: 0;
  height: auto;
}
.video {
  overflow: hidden;
  height: 100%;
  video {
    top: -10%;
    position: relative;
    width: 100%;
    position: relative;
  }
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  30% {
    opacity: 1;
  }
  70% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
</style>
