<template>
  <Header />
  <Footer />
  <MenuMobile>
    <template #main>
      <NavMobile />
    </template>
  </MenuMobile>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import MenuMobile from "@/components/MenuMobile.vue";
import NavMobile from "@/components/NavMobile.vue";

export default {
  name: "HomeView",
  components: { Header, Footer, MenuMobile, NavMobile },
};
</script>
