<template>
  <transition name="fade-modal">
    <div v-if="feedbackModalShow" class="modal">
      <div class="modal-bg" @click="hideFeedbackModal"></div>
      <div class="modal-content modal-content_color_feedback">
        <div v-if="status === 'success'" class="form-message">
          <h3>СПАСИБО!</h3>
          <p>
            Ваша заявка принята. В ближайшее время наш специалист свяжется с
            вами.
          </p>
          <button class="btn-primary" @click="hideFeedbackModal">
            Закрыть
          </button>
        </div>
        <Form v-else ref="form" class="form" @submit="onSubmit">
          <div class="form-title">ОСТАВИТЬ ЗАЯВКУ</div>
          <div
            class="modal-close modal-close_type_inset"
            @click="hideFeedbackModal"
          >
            <svg width="34" height="34" viewBox="0 0 34 34" fill="none">
              <path
                d="m17 16.9997-5.6569-5.6568m0 11.3137L17 16.9997l-5.6569 5.6569ZM17 16.9997l5.6569-5.6568L17 16.9997Zm0 0 5.6569 5.6569L17 16.9997Z"
                stroke="#5F6368"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <FormItem
            v-slot="{ field }"
            name="model"
            class="form-model"
            :rules="isRequired"
            @click="onModelClick('model')"
          >
            <div class="form-select">
              <select v-bind="field" :class="{ selected: field.value }">
                <option value="" disabled selected>Выберите модель</option>
                <option
                  v-for="(item, index) in modelList"
                  :key="index"
                  :value="item.id"
                >
                  {{ item.name }}
                </option>
              </select>
            </div>
          </FormItem>
          <FormItem
            v-if="!paramsDealer"
            v-slot="{ field }"
            name="city"
            class="form-city"
            :rules="isRequired"
            @change="onChangeCity"
            @click="onFieldClick('city')"
          >
            <div class="form-select">
              <select v-bind="field" :class="{ selected: field.value }">
                <option value="" disabled selected>Ваш город</option>
                <option
                  v-for="(item, index) in cities"
                  :key="index"
                  :value="item.id"
                >
                  {{ item.name }}
                </option>
              </select>
            </div>
          </FormItem>
          <FormItem
            v-if="!paramsDealer"
            v-slot="{ field }"
            name="dealer"
            class="form-dealer"
            :rules="isRequired"
            @click="onFieldClick('dealer')"
          >
            <div class="form-select">
              <select
                v-bind="field"
                :class="{ selected: field.value }"
                :disabled="!dealers.length"
              >
                <option value="" disabled selected>Выберите дилера</option>
                <option
                  v-for="(item, index) in dealers"
                  :key="index"
                  :value="item.dealerCode"
                >
                  {{ item.dealerName }}
                </option>
              </select>
            </div>
          </FormItem>
          <FormItem
            name="firstname"
            type="text"
            placeholder="Имя"
            class="form-firstname"
            :rules="isRequired"
            @click="onFieldClick('firstname')"
          />
          <FormItem
            name="lastname"
            type="text"
            placeholder="Фамилия"
            class="form-lastname"
            :rules="isRequired"
            @click="onFieldClick('lastname')"
          />
          <FormItem
            v-slot="{ field }"
            name="phone"
            type="tel"
            class="form-phonenumber"
            :rules="isRequiredPhone"
            @click="onFieldClick('phone')"
          >
            <input
              placeholder="Тел. номер"
              autocomplete="off"
              class="form-control"
              v-bind="field"
              type="tel"
              v-maska="maskPhone"
            />
          </FormItem>
          <FormItem
            name="email"
            type="email"
            placeholder="E-mail"
            class="form-email"
            :rules="validateEmail"
            @click="onFieldClick('email')"
          />
          <FormItem
            name="terms"
            type="checkbox"
            class="form-checkbox"
            :rules="isRequired"
            @click="onCheckboxClick()"
          >
            <span>
              Отправляя сообщение, я соглашаюсь с
              <a
                href="https://www.lada.ru/personal-data"
                target="_blank"
                rel="noopener noreferrer"
                >политикой обработки персональных данных</a
              >, выражаю свое согласие и разрешаю АО «АВТОВАЗ» осуществлять
              обработку моих персональных данных (фамилия, имя, отчество,
              домашний или рабочий мобильный телефоны, адрес электронной почты),
              в том числе сбор, систематизацию, накопление, хранение, уточнение,
              использование, обезличивание, уничтожение персональных данных,
              передачу третьим лицам – уполномоченным АО «АВТОВАЗ» на основании
              договоров организациям (перечень уполномоченных организаций
              размещен по ссылке: https://www.lada.ru/personal-data), в целях,
              связанных с продвижением товаров и услуг АО «АВТОВАЗ», включая:
              направление материалов рекламного характера, связанных с
              деятельностью АО «АВТОВАЗ», проведение статистических исследований
              и&nbsp; маркетинговых программ (акций) и опросов (аудиты,
              анкетирования, исследования), в том числе с целью выяснения мнения
              пользователя относительно продукции АО «АВТОВАЗ». Согласие на
              обработку персональных данных в соответствии с указанными выше
              условиями я предоставляю на 10 (десять) лет. Я уведомлен и
              согласен с тем, что указанное согласие может быть мной отозвано
              посредством направления письменного заявления заказным почтовым
              отправлением с описью вложения, либо вручено лично под подпись.
            </span>
          </FormItem>
          <div class="form-foot">
            <p v-if="status === 'error'" class="form-error-message">
              {{ errorMessage }}
            </p>
            <button
              :disabled="status === 'loading'"
              class="btn-primary form-send-button"
            >
              ОТПРАВИТЬ ЗАПРОС
            </button>
          </div>
        </Form>
      </div>
    </div>
  </transition>
</template>

<script>
import { FEEDBACK_MODEL_LIST } from "@/data/constants";
import { Form } from "vee-validate";
import { mapState, mapMutations } from "vuex";
import { validateStatus, isRequired, isEmail } from "@/utils/validate";
import FormItem from "@/components/FormItem.vue";
import {
  gtmApplicationModel,
  gtmApplicationFields,
  gtmApplicationCheckbox,
  gtmSubmitApplicationSend,
  gtmSubmitApplicationPush,
} from "@/utils/gtm";

export default {
  name: "FeedbackModal",
  components: {
    Form,
    FormItem,
  },
  computed: {
    ...mapState(["feedbackModalShow", "paramsCity", "paramsDealer"]),
  },
  data() {
    return {
      status: "pending", // pending, loading, success, error
      cities: [],
      dealers: [],
      modelList: FEEDBACK_MODEL_LIST,
      maskPhone: `+7 (###) ###-##-##`,
      errorMessage: "Ошибка",
    };
  },
  created() {
    document.addEventListener("keyup", this.keyPressEscape);
  },
  unmounted() {
    document.removeEventListener("keyup", this.keyPressEscape);
  },
  watch: {
    feedbackModalShow(val) {
      if (val) {
        this.status = "pending";
        this.$nextTick(this.update);
      }
    },
  },
  methods: {
    ...mapMutations(["hideFeedbackModal"]),
    update() {
      if (!this.paramsDealer && !this.cities.length) {
        this.fetchCities();
      }
      if (this.paramsCity) {
        this.$refs.form.setFieldValue("city", this.paramsCity);
        this.fetchDealers(this.paramsCity);
      }
    },
    keyPressEscape(e) {
      if (e.key == "Escape") {
        this.hideFeedbackModal();
      }
    },
    isRequiredPhone(value) {
      return isRequired(value, 18);
    },
    isRequired(value) {
      return isRequired(value);
    },
    validateEmail(value) {
      return isEmail(value);
    },
    async fetchCities() {
      try {
        const response = await fetch(`/api/cities`, {
          method: "GET",
        });
        const { status } = response;

        if (!validateStatus(status)) {
          throw new Error(`server response with status ${status}`);
        }

        this.cities = await response.json();
      } catch (error) {
        console.log(error);
      }
    },
    async fetchDealers(cityId) {
      try {
        const response = await fetch(`/api/dealers/${cityId}`, {
          method: "GET",
        });
        const { status } = response;

        if (!validateStatus(status)) {
          throw new Error(`server response with status ${status}`);
        }

        this.$refs.form.setFieldValue("dealer", "");
        this.dealers = await response.json();
      } catch (error) {
        console.log(error);
      }
    },
    async fetchFeedback(data) {
      try {
        const response = await fetch(`/api/forms/common`, {
          method: "POST",
          body: JSON.stringify(data),
        });
        const { status } = response;

        if (!validateStatus(status)) {
          this.errorMessage = await response.json();
          throw new Error(`server response with status ${status}`);
        }
      } catch (error) {
        throw new Error(error);
      }
    },
    onChangeCity(e) {
      this.dealers = [];
      this.fetchDealers(e.target.value);
    },
    onModelClick(name) {
      gtmApplicationModel();
      this.onFieldClick(name);
    },
    onFieldClick(name) {
      gtmApplicationFields(name);
    },
    onCheckboxClick() {
      gtmApplicationCheckbox();
    },
    async onSubmit(values) {
      this.status = "loading";
      gtmSubmitApplicationSend();

      const form = {
        type: "buy",
        gaclicktime: Date.now(),
        url: location.href,
        ...values,
      };
      delete form.terms;
      delete form.city;

      if (this.paramsDealer) {
        form.dealer = +this.paramsDealer;
      }

      try {
        await this.fetchFeedback(form);
        this.status = "success";
        gtmSubmitApplicationPush();
      } catch (error) {
        this.status = "error";
      }
    },
  },
};
</script>
