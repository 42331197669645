export const PANORAMA = {
  options: {
    name: "interior-vesta-sw",
    items: [],
  },
  infospots: [],
};

export const GALLERY = {
  folder: "faw-placeholder/exterior",
  colorActive: 0,
  colors: [
    {
      title: 'СЕРЕБРИСТЫЙ "ПЛАТИНА" (691) - МЕТАЛЛИК',
      hex: "#C3C4C4",
      folder: "grey",
      fileName: {
        light: "grey_day00",
        light2: "grey_2day00",
      },
    },
  ],
  breakpoints: [
    {
      slideIndex: 20, // 10
      items: [],
    },
    {
      slideIndex: 80, // 70
      items: [],
    },
    {
      slideIndex: 200, // 190
      items: [],
    },
  ],
};
