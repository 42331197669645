<template>
  <div
    ref="panoramaInterior"
    class="panorama-container panorama-interior"
    :class="{ show: show }"
  ></div>
  <div
    ref="popoverBackdrop"
    class="popover-backdrop"
    :class="{ popover_state_show: pinActive }"
  ></div>
  <div style="display: none">
    <slot />
  </div>
</template>

<script>
import * as PANOLENS from "@/libs/panolens/panolens.js";

import { mapState, mapMutations } from "vuex";
import { THEMES } from "@/data/constants";
import { degToRad, updateThemeInAllPanoramas } from "@/utils/helpers";
import { gtmEvent } from "@/utils/gtm";

const markerImage = require("@/assets/img/icons/marker-plus.svg");
const markerSize = 280;

export default {
  props: {
    panoramaOptions: {
      requried: true,
    },
    infospots: {
      requried: true,
    },
    show: Boolean,
  },
  name: "PanoramaInterior",
  data() {
    return {
      panorama: [],
      panoramaIndexActive: 0,
      viewer: null,
      cameraStartPosition: {},
      pinActive: false,
    };
  },
  computed: {
    ...mapState(["theme", "isMobile", "popoverModalShow", "feedbackModalShow"]),
  },
  watch: {
    theme(theme) {
      updateThemeInAllPanoramas(theme, this.changeTheme);
    },
    feedbackModalShow(state) {
      state ? this.disableKeys() : this.enableKeys();
    },
    pinActive(state) {
      state ? this.disableKeys() : this.enableKeys();
    },
    show(state) {
      if (state) {
        const isLight = this.theme === THEMES[0];

        // переключаем панормаму только когда она видна на экране
        // в фоне она не всегда корректно переключается и появляется черный экран
        if (isLight && this.panoramaIndexActive === 1) {
          this.setPanorama(0);
        } else if (!isLight && this.panoramaIndexActive === 0) {
          this.setPanorama(1);
        }
      }
    },
  },
  mounted() {
    this.init();
  },
  unmounted() {
    this.destroy();
  },
  methods: {
    ...mapMutations([
      "showPopoverModal",
      "hidePopoverModal",
      "setPopoverContent",
    ]),
    init() {
      if (this.viewer) return false;
      this.setupViewer();

      this.panoramaOptions.items.forEach((options) => {
        const source = this.isMobile ? options.srcMobile : options.src;
        const panorama = this.addPanorama(source);
        this.startLookAt(panorama, options.startLookAt);
      });

      this.addMarkers();
      this.changeTheme(this.theme);

      this.$refs.popoverBackdrop.addEventListener(
        "mouseenter",
        this.onHideInfospot
      );

      document.addEventListener("showVideo", this.onHideInfospot);
      document.addEventListener("showEnjoy", this.onHideInfospot);
      document.addEventListener("mousedown", this.onMoveStartWhenOpenPopover);
      document.addEventListener("mouseup", this.onMoveEndWhenOpenPopover);
    },
    destroy() {
      this.panorama.forEach((e) => {
        e.dispose();
      });
      this.viewer.destroy();
      this.viewer = null;
      this.panorama = [];
      document.removeEventListener("showVideo", this.onHideInfospot);
      document.removeEventListener("showEnjoy", this.onHideInfospot);
      document.removeEventListener(
        "mousedown",
        this.onMoveStartWhenOpenPopover
      );
      document.removeEventListener("mouseup", this.onMoveEndWhenOpenPopover);
    },
    addMarkers() {
      this.panorama.forEach((panorama) => {
        this.infospots.forEach((infospot) => {
          const {
            position: { x, y, z },
            size,
            className,
          } = infospot;
          this.addMarker(
            panorama,
            { x, y, z },
            size | markerSize,
            document.getElementsByClassName(className)[0]
          );
        });
      });
    },
    addMarker(panorama, position, scale, elHover) {
      const infospot = new PANOLENS.Infospot(scale, markerImage, false);

      infospot.position.set(position.x, position.y, position.z);
      infospot.addHoverElement(elHover, 0);
      panorama.add(infospot);

      infospot.addEventListener("click", this.onClick);
      infospot.addEventListener("hoverenter", this.onHoverEnter);
    },
    startLookAt(panorama, deg, axios = "y") {
      const angle = degToRad(deg);

      panorama.rotation[axios] = angle;
      panorama.position["y"] = -1300;
    },
    addPanorama(url, cb) {
      const panorama = new PANOLENS.ImagePanorama(url);
      this.viewer.add(panorama);
      this.panorama.push(panorama);
      if (cb) {
        panorama.addEventListener("load", cb, false);
      }
      return panorama;
    },
    setPanorama(index) {
      this.panoramaIndexActive = index;
      this.viewer.setPanorama(this.panorama[index]);
    },
    setCameraPosition(x, y, z) {
      this.viewer.camera.position.x = x ? x : this.viewer.camera.position.x;
      this.viewer.camera.position.y = y ? y : this.viewer.camera.position.y;
      this.viewer.camera.position.z = z ? z : this.viewer.camera.position.z;
    },
    setupViewer() {
      const container = this.$refs.panoramaInterior;
      const { fov, posZ } = this.$route.query;
      const cameraFov = fov || 40;
      const cameraPositionZ = posZ || 3500;

      this.viewer = new PANOLENS.Viewer({
        container: container,
        cameraFov: cameraFov,
        controlBar: false,
        output: "console",
        autoHideInfospot: false,
        name: this.panoramaOptions.name,
      });
      if (cameraPositionZ > 0) {
        this.viewer.camera.position.z = cameraPositionZ;
      }

      this.viewer.OrbitControls.noZoom = true;

      setTimeout(() => {
        this.setCameraStartPosition(this.viewer.getCamera().position);
      }, 0);
    },
    setCameraStartPosition(vector3) {
      this.cameraStartPosition = Object.assign({}, vector3);
    },
    changeTheme(theme) {
      if (theme === THEMES[0]) {
        if (this.show) {
          this.setPanorama(0);
        }
      } else if (theme === THEMES[1]) {
        if (this.show) {
          this.setPanorama(1);
        }
      }
    },
    disableKeys() {
      this.viewer.disableKeys();
    },
    enableKeys() {
      this.viewer.enableKeys();
    },
    onHideInfospot(e) {
      const isButtonEnjoy = e.target?.classList?.contains("btn-enjoy");

      if (isButtonEnjoy) return false;
      if (this.pinActive || this.popoverModalShow) {
        document
          .querySelector(".panolens-infospot.popover_state_show")
          ?.classList.remove("popover_state_show");
        this.pinActive = false;
        this.hidePopoverModal();
      }
    },
    onClick(e) {
      if (!this.isMobile) return false;
      const id = e.target.element.getAttribute("data-id");
      const data = this.infospots.find((e) => e.className === id);
      const { media, desc, title, gtmReachGoal: name } = data;
      this.showPopoverModal();
      this.setPopoverContent({
        title,
        desc,
        media,
      });

      if (name) {
        gtmEvent(name);
      }
    },
    onHoverEnter(e) {
      if (this.isMobile) return false;
      if (this.pinActive) return false;

      if (e) {
        const id = e.target.element.getAttribute("data-id");
        const data = this.infospots.find((e) => e.className === id);
        const { gtmReachGoal: name } = data;

        e.target.element.classList.add("popover_state_show");

        if (name) {
          gtmEvent(name);
        }
      }
      this.pinActive = true;
    },
    onHoverLeave() {
      if (this.isMobile) return false;

      document
        .querySelector(".popover_state_show")
        ?.classList.remove("popover_state_show");
      this.pinActive = false;
    },
    onMoveStartWhenOpenPopover() {
      document.addEventListener("mousemove", this.onHideInfospot);
    },
    onMoveEndWhenOpenPopover() {
      document.removeEventListener("mousemove", this.onHideInfospot);
    },
  },
};
</script>
