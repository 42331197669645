<template>
  <button :class="['button', { highlight, dark }]" @click="onClick"></button>
</template>

<script>
import { mapState, mapMutations } from "vuex";
export default {
  name: "HighlightButton",
  props: {
    dark: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState(["highlight"]),
  },
  methods: {
    ...mapMutations(["refreshHighlight"]),
    onClick() {
      this.refreshHighlight();
      this.$emit("click-button");
    },
  },
};
</script>

<style scoped lang="scss">
.button.highlight {
  &::before {
    animation: highlight linear 2s infinite;
  }
}

.button {
  @include reset-button;
  position: absolute;
  z-index: 10;
  // opacity: .5;
  // border-radius: uniW(4);
  border-radius: 8px;
  &::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    opacity: 0;
    content: "";
    border-radius: 8px;
    pointer-events: none;
    @include mobile {
      border-radius: uniWM(4);
    }
  }
  &.dark {
    &::before {
      background: #5f6368;
    }
  }
  &.circle {
    &::before {
      border-radius: 100px;
    }
  }
  @include mobile {
    border-radius: uniWM(4);
  }
}

@keyframes highlight {
  0% {
    opacity: 0;
    transform: scale(0.8);
  }
  40% {
    opacity: 0.3;
    transform: scale(1);
  }
  50% {
    opacity: 0.3;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(0.9);
  }
}
</style>
