<template>
  <section>
    <div class="pannel">
      <swiper
        @swiper="onSwiper"
        @slideChange="onSwiperSlideChange"
        :direction="'vertical'"
        :slidesPerView="'auto'"
        :freeMode="true"
        :mousewheel="true"
        :modules="modules"
        :speed="1000"
        :resistanceRatio="0.5"
      >
        <swiper-slide>
          <img ref="img" src="@/assets/img/enjoy/settings.webp" alt="" />
          <highlight-button
            @click-button="$emit('change', 'settings-sound')"
            style="width: 17%; height: 10%; top: 15.2%; left: 2%"
          />
        </swiper-slide>
        <swiper-slide>
          <img
            ref="img"
            class="img-1"
            src="@/assets/img/enjoy/settings.webp"
            alt=""
          />
        </swiper-slide>
        <swiper-slide>
          <img
            ref="img"
            class="img-2"
            src="@/assets/img/enjoy/settings.webp"
            alt=""
          />
          <highlight-button
            @click-button="$emit('change', 'settings-auto')"
            style="width: 46%; height: 19%; top: 1%; left: 3%"
          />
        </swiper-slide>
      </swiper>
    </div>
  </section>
  <img
    v-if="activeIndex !== 0"
    src="@/assets/img/enjoy/dark-bottom.webp"
    class="swipe-down revert"
  />
  <img v-else src="@/assets/img/enjoy/dark-bottom.webp" class="swipe-down" />
  <img
    v-if="activeIndex !== 0"
    src="@/assets/img/enjoy/btn-bottom-revert.webp"
    :class="['btn-down revert', { highlight }]"
    @click="slideTo(activeIndex - 1), refreshHighlight()"
  />
  <img
    v-else
    src="@/assets/img/enjoy/btn-bottom.webp"
    :class="['btn-down', { highlight }]"
    @click="slideTo(2), refreshHighlight()"
  />

  <img src="@/assets/img/enjoy/nav-settings.webp" class="nav" />
</template>

<script>
import { ref } from "vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/scrollbar";
import { FreeMode, Mousewheel } from "swiper";
import { mapState, mapMutations } from "vuex";
import HighlightButton from "../HighlightButton.vue";

export default {
  components: { HighlightButton, Swiper, SwiperSlide },
  name: "EnjoySettings",
  props: {
    globalHighlight: Boolean,
  },
  emits: ["change"],
  data() {
    return {
      // percentScroll: 0,
      // swWrapper: null,
    };
  },
  // mounted() {
  //   this.swWrapper = document.getElementsByClassName("swiper-wrapper")[0];
  //   this.swWrapper.addEventListener("mousewheel", this.scrollDown, false);
  //   this.swWrapper.addEventListener("mousemove", this.scrollDown);
  // },
  // beforeUnmount() {
  //   this.swWrapper.removeEventListener("mousewheel", this.scrollDown);
  //   this.swWrapper.removeEventListener("mousemove", this.scrollDown);
  // },
  computed: {
    ...mapState(["highlight"]),
  },
  methods: {
    ...mapMutations(["refreshHighlight"]),
    // scrollDown() {
    //   let translateCss = this.swWrapper.style.transform.split(", ");
    //   let translateY = translateCss[1];
    //   let translateNum = -translateY.split("px")[0];
    //   this.percentScroll = (translateNum / this.$refs.img.scrollHeight) * 100;
    // },
  },
  setup() {
    const swiper = ref(0);
    const activeIndex = ref(1);
    const onSwiper = (sw) => {
      swiper.value = sw;
      activeIndex.value = 0;
    };
    const slideTo = (slide) => {
      swiper.value.slideTo(slide);
    };

    const onSwiperSlideChange = (sw) => {
      activeIndex.value = sw.activeIndex;
    };

    return {
      onSwiper,
      slideTo,
      activeIndex,
      onSwiperSlideChange,
      modules: [FreeMode, Mousewheel],
    };
  },
};
</script>

<style lang="scss" scoped>
section {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  > img,
  > video {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: 50% 50%;
  }
}
.btn-down {
  width: 16%;
  position: absolute;
  z-index: 1;
  bottom: 11%;
  right: 5%;
  cursor: pointer;
  &.highlight {
    animation: opacity linear 2s infinite;
  }
  &.revert {
    top: 5%;
    bottom: auto;
  }
}
.swipe-down {
  position: absolute;
  width: 100%;
  bottom: 9%;
  left: 0;
  z-index: 1;
  pointer-events: none;
  &.revert {
    top: 0px;
    bottom: auto;
    transform: scale(1, -1);
  }
}
.nav {
  position: absolute;
  width: 100%;
  bottom: -2px;
  left: 0;
  z-index: 2;
}
.pannel {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;

  .swiper {
    width: 100%;
    height: 100%;
    .swiper-slide {
      flex: 0 0 75%;
      height: auto;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      height: 100%;
      overflow: hidden;
      scroll-snap-align: start;
      position: relative;
      // @for $i from 1 through 2 {
      //   &:nth-child(#{$i}) {
      //     img {
      //       margin-top: ($i - 1) * (500% / -5);
      //     }
      //   }
      // }
      &:nth-child(1) {
        img {
          margin-top: 0;
        }
      }

      &:nth-child(2) {
        img {
          margin-top: -99.6%;
        }
      }

      &:nth-child(3) {
        max-height: 33%;
        img {
          margin-top: -199%;
        }
      }
      // .img-1 {
      //   width: calc(500% / 1.1);
      //   top: -100%;
      //   position: relative;
      // }
      // .img-2 {
      //   top: -200%;
      //   position: relative;
      // }
      // .img-3 {
      //   top: -300%;
      //   position: relative;
      // }
    }
    img {
      // height: calc(500% / 1.1);
      width: 100%;
    }
  }
  ul {
    @include reset-list;
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    // overflow-y: scroll;
    // scroll-snap-type: y mandatory;
    height: 100%;
    // &::-webkit-scrollbar {
    //   display: none;
    // }
    li {
      position: relative;
      img {
        width: 100%;
      }
    }
  }
}
@keyframes opacity {
  0% {
    opacity: 0;
    transform: scale(0.8);
  }
  40% {
    opacity: 1;
    transform: scale(1);
  }
  50% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(0.9);
  }
}
</style>
