<template>
  <section v-if="step === 1">
    <div class="rel">
      <img src="@/assets/img/enjoy/settings-sound-menu.webp" />
      <highlight-button
        @click-button="step = 2"
        style="width: 62%; height: 9%; top: 12%; left: 2%"
      />
      <highlight-button
        @click-button="$emit('change', 'settings')"
        style="width: 19%; height: 6%; top: 3.5%; left: 2%"
      />
    </div>
  </section>
  <section v-else>
    <div class="rel">
      <img :src="require(`@/assets/img/enjoy/${position}.webp`)" />
      <highlight-button
        @click-button="step = 1"
        style="width: 19%; height: 6%; top: 3.5%; left: 2%"
      />
      <highlight-button
        v-if="position !== 'center'"
        @click="position = 'center'"
        style="width: 17%; top: 13.5%"
      />
      <highlight-button
        v-if="position !== 'driver'"
        @click="position = 'driver'"
        style="width: 22%; top: 21.5%"
      />
      <highlight-button
        v-if="position !== 'first'"
        @click="position = 'first'"
        style="width: 28%; top: 29.5%"
      />
      <highlight-button
        v-if="position !== 'last'"
        @click="position = 'last'"
        style="width: 25%; top: 37.6%"
      />
      <highlight-button
        v-if="position !== 'user'"
        @click="position = 'user'"
        style="width: 31%; top: 45.8%"
      />
    </div>
  </section>
</template>

<script>
import HighlightButton from "../HighlightButton.vue";
export default {
  components: { HighlightButton },
  name: "EnjoySettingsSound",
  data() {
    return {
      step: 1,
      position: "center",
    };
  },
};
</script>

<style lang="scss" scoped>
section {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  .rel {
    position: relative;
    width: 100%;
    > img,
    > video {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: 50% 50%;
    }
  }
  .button {
    position: absolute;
    height: 7%;
    left: 2%;
  }
}
</style>
