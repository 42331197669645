<template>
  <transition name="fade-center-modal">
    <div v-if="enjoyModalShow" class="modal modal_place_center">
      <div class="modal-bg" @click="hideEnjoyModal"></div>
      <div class="modal-close modal-close_show_mobile" @click="hideEnjoyModal">
        <svg width="34" height="34" viewBox="0 0 34 34" fill="none">
          <path
            d="m17 16.9997-5.6569-5.6568m0 11.3137L17 16.9997l-5.6569 5.6569ZM17 16.9997l5.6569-5.6568L17 16.9997Zm0 0 5.6569 5.6569L17 16.9997Z"
            stroke="#5F6368"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
      <div class="modal-content modal-content_size_auto">
        <Enjoy />
      </div>
    </div>
  </transition>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import Enjoy from "@/components/Enjoy.vue";

export default {
  name: "EnjoyModal",
  components: { Enjoy },
  computed: {
    ...mapState(["enjoyModalShow", "enjoyModalModel"]),
  },
  mounted() {
    document.addEventListener("keyup", this.onClose);
  },
  unmounted() {
    document.removeEventListener("keyup", this.onClose);
  },
  methods: {
    ...mapMutations(["hideEnjoyModal"]),
    onClose(event) {
      if (event.keyCode === 27) {
        this.hideEnjoyModal();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.modal:deep {
  height: 100%;
}
.modal-content {
  height: calc(100vh - uniW(80) - 80px);
  @include mobile {
    height: auto;
  }
}
</style>
