<template>
  <Header>
    <template v-slot:header-left>
      <ButtonBack />
    </template>
  </Header>
  <div class="video-display" :class="{ mobile: isMobile }">
    <video
      ref="video"
      :class="{ 'video-play-button': !videoPlaying }"
      :src="getCurrentVideo.src"
      @click="onVideoClick"
    ></video>
    <div class="video-display__process">
      <span :style="{ width: `${videoProgress}%` }"></span>
    </div>
    <button class="video-display__button" @click="onOpenFullscreen">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path
          d="M3 21H9M14.5 9.5L21 3L14.5 9.5ZM21 3H15H21ZM21 3V9V3ZM3 21L9.5 14.5L3 21ZM3 21V15V21Z"
          stroke="white"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </button>
  </div>
  <div>
    <transition name="fade">
      <img
        v-if="isMobile"
        class="video-bg"
        :src="RESOURCES.background.light.srcMobile"
        alt=""
      />
      <img
        v-else
        class="video-bg"
        :src="RESOURCES.background.light.src"
        alt=""
      />
    </transition>
  </div>
  <Footer>
    <template #controls>
      <div class="video-controls" :class="{ mobile: isMobile }">
        <VideoSwiper
          :videos="videos"
          :play="onPlay"
          :playing="videoPlaying"
          :videoActive="videoActive"
        />
      </div>
    </template>
  </Footer>
  <MenuMobile />
</template>

<script>
import { mapState } from "vuex";
import { VIDEO_LIST } from "@/data/cinema";
import Header from "@/components/Header.vue";
import ButtonBack from "@/components/ButtonBack.vue";
import Footer from "@/components/Footer.vue";
import VideoSwiper from "@/components/VideoSwiper.vue";
import MenuMobile from "@/components/MenuMobile.vue";
import { RESOURCES } from "@/data/cinema";
import { gtmCinemaPlay } from "@/utils/gtm";

export default {
  name: "CinemaView",
  components: { Header, ButtonBack, Footer, VideoSwiper, MenuMobile },
  computed: {
    ...mapState(["isMobile", "theme"]),
    getCurrentVideo() {
      return this.videos[this.videoActive];
    },
  },
  data() {
    return {
      videoActive: 0,
      videoPlaying: false,
      videoProgress: 0,
      videos: VIDEO_LIST,
      RESOURCES,
    };
  },
  methods: {
    progress() {
      if (this.isMobile) return false;
      if (!this.videoPlaying) return false;
      const video = this.$refs.video;

      if (video) {
        const currentPercent = Math.ceil(
          (video.currentTime / video.duration) * 100
        );
        this.videoProgress = currentPercent;
      }
      requestAnimationFrame(this.progress);
    },
    next() {
      const length = this.videos.length;
      const next = this.videoActive + 1;

      if (length > next) {
        this.onPlay(next);
      } else {
        this.onPause();
      }
    },
    stop() {
      this.onPause();
    },
    /**
     * Метод для переключения состояния превью при выходе/паузе из fullscreen video
     */
    onPauseFullscreen() {
      if (this.isMobile) {
        this.videoPlaying = false;
      }
    },
    onFullscreenChange() {
      if (this.isMobile) {
        if (!document.fullscreenElement) {
          this.onPause();
        }
      }
    },
    onVideoClick() {
      if (this.videoPlaying) {
        this.onPause();
        return;
      }

      this.onPlay(this.videoActive);
    },
    onPause() {
      this.videoPlaying = false;
      this.$refs.video.pause();
      if (this.isMobile) this.$refs.video.load();
      this.$refs.video.removeEventListener("play", this.progress);
    },
    onPlay(index) {
      const isCurrent = this.videoActive === index;
      if (isCurrent && this.videoPlaying) {
        this.onPause();
        return;
      }
      this.videoActive = index;
      this.videoPlaying = true;
      gtmCinemaPlay();

      if (this.isMobile) {
        this.onOpenFullscreen();
      }
      this.$nextTick(() => {
        this.$refs.video.play();
        this.$refs.video.addEventListener("play", this.progress);
        this.$refs.video.addEventListener("ended", this.stop);
        this.$refs.video.addEventListener("pause", this.onPauseFullscreen);
        this.$refs.video.addEventListener(
          "fullscreenchange",
          this.onFullscreenChange
        );
        this.$refs.video.addEventListener(
          "webkitendfullscreen",
          this.onFullscreenChange
        );
      });
    },
    onOpenFullscreen() {
      const video = this.$refs.video;
      if (video.requestFullscreen) {
        video.requestFullscreen();
      } else if (video.webkitRequestFullscreen) {
        /* Safari */
        video.webkitRequestFullscreen();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.video-display {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  margin-top: -8vw;
  z-index: 10;
  width: 54vw;
  aspect-ratio: 596/336;
  background: var(--colorBlack);
  &.mobile {
    width: 0;
    height: 0;
  }
  video {
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
  &__button {
    @include reset-button;
    position: absolute;
    bottom: uniW(16);
    right: uniW(8);
    &:hover {
      svg path {
        stroke: var(--colorOrangeLight);
      }
    }
    svg {
      width: uniW(24);
      height: uniW(24);
      path {
        transition: var(--transitionTime) var(--cubicBezier);
      }
    }
  }
  &__process {
    position: absolute;
    bottom: uniW(8);
    left: uniW(8);
    width: calc(100% - uniW(16));
    height: uniW(4);
    background-color: rgba(196, 196, 196, 0.5);
    border-radius: uniW(8);
    span {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 0;
      background-color: var(--colorOrange);
      transition: width 0.1s linear;
      border-radius: uniW(8);
      &::before {
        position: absolute;
        top: 50%;
        right: 0;
        width: uniW(10);
        height: uniW(10);
        background-color: var(--colorOrange);
        margin-top: uniW(-5);
        margin-right: uniW(-5);
        border-radius: uniW(10);
        content: "";
      }
    }
  }
}
.video-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  object-fit: cover;
  object-position: 50% 45%;
}

.video-controls {
  width: uniW(396);
  margin-left: auto;
  margin-right: auto;
  margin-bottom: uniW(-4);
  @include mobile {
    width: calc(100vw - uniWM(16));
    margin-left: uniWM(-16);
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: all 0.5s var(--cubicBezier);
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
