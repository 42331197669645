<template>
  <section>
    <img
      :src="
        require(`@/assets/img/enjoy/2${whiteThemeEnjoy ? '-white' : ''}.webp`)
      "
      alt=""
    />
    <div class="panel">
      <ul ref="ul">
        <swiper
          @swiper="onSwiper"
          @slideChange="onSwiperSlideChange"
          :direction="'horizontal'"
          :slidesPerView="'auto'"
          :freeMode="true"
          :mousewheel="true"
          :modules="modules"
          :speed="1000"
        >
          <swiper-slide>
            <li>
              <img
                :src="
                  require(`@/assets/img/enjoy/3${
                    whiteThemeEnjoy ? '-white' : ''
                  }.webp`)
                "
                alt=""
              />
            </li>
          </swiper-slide>
          <swiper-slide>
            <li>
              <img
                :src="
                  require(`@/assets/img/enjoy/3${
                    whiteThemeEnjoy ? '-white' : ''
                  }.webp`)
                "
                alt=""
              />
              <highlight-button
                class="circle"
                @click-button="changeTheme"
                :dark="whiteThemeEnjoy"
                style="width: 35%; height: 35%; top: 60%; left: 34%"
              />
            </li>
          </swiper-slide>
          <swiper-slide>
            <li>
              <img
                :src="
                  require(`@/assets/img/enjoy/3${
                    whiteThemeEnjoy ? '-white' : ''
                  }.webp`)
                "
                alt=""
              />
            </li>
          </swiper-slide>
          <swiper-slide>
            <li>
              <img
                :src="
                  require(`@/assets/img/enjoy/3${
                    whiteThemeEnjoy ? '-white' : ''
                  }.webp`)
                "
                alt=""
              />
            </li>
          </swiper-slide>
        </swiper>
      </ul>
    </div>
    <img
      v-if="activeIndex !== 0"
      src="@/assets/img/enjoy/dark-left.webp"
      class="swipe-left left"
      :style="{
        opacity: whiteThemeEnjoy ? '0.3' : '1',
      }"
    />
    <img
      v-if="activeIndex !== 2"
      src="@/assets/img/enjoy/dark-left.webp"
      class="swipe-left"
      :style="{
        opacity: whiteThemeEnjoy ? '0.3' : '1',
      }"
    />
    <img
      v-if="activeIndex !== 2"
      :src="
        require(`@/assets/img/enjoy/btn-left${
          whiteThemeEnjoy ? '-white' : ''
        }.webp`)
      "
      :class="['btn-left', { highlight }]"
      @click="slideTo(activeIndex + 1), refreshHighlight()"
    />
    <img
      v-if="activeIndex !== 0"
      :src="
        require(`@/assets/img/enjoy/btn-left${
          whiteThemeEnjoy ? '-white' : ''
        }-revert.webp`)
      "
      :class="['btn-left left', { highlight }]"
      @click="slideTo(activeIndex - 1), refreshHighlight()"
    />
  </section>
</template>

<script>
import { ref } from "vue";
import { mapMutations, mapState } from "vuex";
import HighlightButton from "../HighlightButton.vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/scrollbar";
import { FreeMode, Mousewheel } from "swiper";

export default {
  components: { HighlightButton, Swiper, SwiperSlide },
  name: "EnjoyMain",
  setup() {
    const swiper = ref(0);
    const activeIndex = ref(1);
    const onSwiper = (sw) => {
      swiper.value = sw;
      activeIndex.value = swiper.value.activeIndex;
    };
    const onSwiperSlideChange = (sw) => {
      activeIndex.value = sw.activeIndex;
    };
    const slideTo = (slide) => {
      swiper.value.slideTo(slide);
    };

    return {
      onSwiper,
      onSwiperSlideChange,
      activeIndex,
      slideTo,
      modules: [FreeMode, Mousewheel],
    };
  },
  computed: {
    ...mapState(["whiteThemeEnjoy", "highlight"]),
  },
  methods: {
    ...mapMutations(["setWhiteThemeEnjoy", "refreshHighlight"]),
    changeTheme() {
      this.setWhiteThemeEnjoy(!this.whiteThemeEnjoy);
    },
  },
};
</script>

<style lang="scss" scoped>
section {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  > img,
  > video {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: 50% 50%;
  }
}
.btn-left {
  position: absolute;
  height: 11%;
  width: auto;
  bottom: 11%;
  // pointer-events: none;
  opacity: 0;
  z-index: 1;
  right: 5%;
  cursor: pointer;
  &.highlight {
    animation: opacity linear 2s infinite;
  }
  &.left {
    right: auto;
    left: 5%;
  }
}
.swipe-left {
  position: absolute;
  height: 91%;
  right: 0;
  top: 0;
  width: auto;
  z-index: 1;
  pointer-events: none;
  &.left {
    right: auto;
    left: 0;
    transform: scale(-1, 1);
  }
}
.panel {
  position: absolute;
  bottom: 10%;
  left: 0;
  width: 100%;
  z-index: 0;
  .swiper {
    width: 100%;
    height: 100%;
    &-wrapper {
      .swiper-slide {
        flex: 0 0 50%;
        width: 100%;
        scroll-snap-align: start;
        overflow: hidden;
        position: relative;
        // @for $i from 1 through 5 {
        //   &:nth-child(#{$i}) {
        //     img {
        //       margin-left: ($i - 1) * (500% / 1.1 / -5);
        //     }
        //   }
        // }
        &:nth-child(1) {
          img {
            margin-left: 0;
          }
        }
        &:nth-child(2) {
          img {
            margin-left: -100%;
          }
        }
        &:nth-child(3) {
          img {
            margin-left: -200%;
          }
        }
        &:nth-child(4) {
          img {
            margin-left: -364%;
          }
        }
      }
      li {
        img {
          width: 335.545455%;
          margin-bottom: -10%;
        }
      }
    }
  }

  ul {
    @include reset-list;
    width: 100%;
    display: flex;
    position: relative;
  }
}
@keyframes opacity {
  0% {
    opacity: 0;
    transform: scale(0.8);
  }
  40% {
    opacity: 1;
    transform: scale(1);
  }
  50% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(0.9);
  }
}
</style>
