<template>
  <Header>
    <template v-slot:header-left>
      <ButtonBack />
    </template>
    <template v-slot:header-center>
      <ButtonSwitcher />
    </template>
  </Header>
</template>

<script>
import Header from "@/components/Header.vue";
import ButtonBack from "@/components/ButtonBack.vue";
import ButtonSwitcher from "@/components/ButtonSwitcher.vue";
export default {
  name: "HeaderCar",
  components: {
    Header,
    ButtonBack,
    ButtonSwitcher,
  },
};
</script>
