<template>
  <div v-if="type === 'text' || type === 'email'" class="form-item">
    <Field
      autocomplete="off"
      class="form-control"
      :placeholder="placeholder"
      :type="type"
      :name="name"
      :rules="rules"
    >
    </Field>
    <ErrorMessage class="form-error" :name="name" />
  </div>
  <div v-else-if="type === 'checkbox'" class="form-item">
    <label class="form-checkbox">
      <Field
        autocomplete="off"
        :name="name"
        :type="type"
        :value="true"
        :rules="rules"
      >
      </Field>
      <slot />
      <ErrorMessage
        class="form-checkbox-error form-error_type_checkbox"
        :name="name"
      />
    </label>
  </div>
  <div v-else class="form-item">
    <Field
      autocomplete="off"
      class="form-control"
      v-slot="{ field }"
      :placeholder="placeholder"
      :type="type"
      :name="name"
      :rules="rules"
    >
      <slot :field="field" />
    </Field>
    <ErrorMessage class="form-error" :name="name" />
  </div>
</template>

<script>
import { Field, ErrorMessage } from "vee-validate";

export default {
  name: "FormItem",
  props: {
    name: String,
    placeholder: String,
    type: {
      type: String,
    },
    rules: {
      type: Function,
      required: false,
    },
  },
  components: {
    Field,
    ErrorMessage,
  },
};
</script>
