<template>
  <button class="switcher" :class="{ active: isActive }" @click="onChangeSound">
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        class="fill"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17 12.7146V5.30762C17.0005 5.07117 16.937 4.83903 16.8162 4.636C16.6955 4.43298 16.522 4.2667 16.3144 4.15494C16.1068 4.04319 15.8729 3.99015 15.6376 4.00151C15.4023 4.01286 15.1745 4.08818 14.9785 4.21941L10.4841 7.22749L17 12.7146ZM8.39966 8.08687H5.3C4.95522 8.08687 4.62456 8.22434 4.38076 8.46904C4.13696 8.71374 4 9.04562 4 9.39168V14.6109C4 14.957 4.13696 15.2889 4.38076 15.5336C4.62456 15.7783 4.95522 15.9157 5.3 15.9157H8.8061C9.06276 15.9153 9.3138 15.9911 9.5276 16.1336L14.9785 19.7806C15.1743 19.9117 15.4018 19.987 15.6369 19.9985C15.872 20.0099 16.1058 19.9571 16.3133 19.8457C16.5208 19.7342 16.6943 19.5683 16.8152 19.3657C16.9362 19.163 17 18.9312 17 18.695V15.3293L8.39966 8.08687Z"
        fill="#4F5862"
      />
      <path
        class="stroke"
        d="M21 20L2 4"
        stroke="#4F5862"
        stroke-width="2"
        stroke-linecap="round"
      />
    </svg>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        class="fill"
        d="M1 14.6109V9.39168C1 9.04562 1.13696 8.71374 1.38076 8.46904C1.62456 8.22434 1.95522 8.08687 2.3 8.08687H5.8061C6.06289 8.08693 6.31395 8.01065 6.5276 7.86766L11.9785 4.21941C12.1745 4.08818 12.4023 4.01286 12.6376 4.00151C12.8729 3.99015 13.1068 4.04319 13.3144 4.15494C13.522 4.2667 13.6955 4.43298 13.8162 4.636C13.937 4.83903 14.0005 5.07117 14 5.30762V18.695C14 18.9312 13.9362 19.163 13.8152 19.3657C13.6943 19.5683 13.5208 19.7342 13.3133 19.8457C13.1058 19.9571 12.872 20.0099 12.6369 19.9985C12.4018 19.987 12.1743 19.9117 11.9785 19.7806L6.5276 16.1336C6.3138 15.9911 6.06276 15.9153 5.8061 15.9157H2.3C1.95522 15.9157 1.62456 15.7783 1.38076 15.5336C1.13696 15.2889 1 14.957 1 14.6109Z"
        fill="white"
      />
      <path
        d="M18.7061 7C18.7061 7 21.4119 9.02941 21.4119 12.4118C21.4119 15.7941 18.7061 17.8235 18.7061 17.8235"
        class="stroke"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M16 9.70605C16 9.70605 17.3529 10.7208 17.3529 12.4119C17.3529 14.1031 16 15.1178 16 15.1178"
        class="stroke"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </button>
</template>

<script>
export default {
  name: "SwitcherSound",
  data() {
    return {
      isActive: false,
    };
  },
  methods: {
    onChangeSound() {
      this.isActive = !this.isActive;
      this.$emit("change", !this.isActive);
    },
  },
};
</script>

<style scoped lang="scss">
.switcher {
  @include reset-button;
  margin: uniW(-2) 0;
  width: uniW(88);
  height: uniW(44);
  background: rgba(0, 0, 0, 0.4);
  border: uniW(1) solid rgba(255, 255, 255, 0.4);
  box-sizing: border-box;
  border-radius: uniW(100);
  cursor: pointer;
  display: grid;
  grid-template-columns: 1fr 1fr;
  place-items: center;
  gap: uniW(4);
  position: absolute;
  top: calc(uniW(24) + 80px); // компенсация для хедера
  right: uniW(24);
  @include mobile {
    margin: uniWM(-2) 0;
    width: uniWM(88);
    height: uniWM(44);
    border-width: uniWM(1);
    border-radius: uniWM(100);
    gap: uniWM(4);
    top: calc(uniWM(16) + 56px); // компенсация для хедера
    right: uniWM(16);
  }
  &.active {
    &::before {
      transform: translateX(calc(100% + uniW(4)));
      @include mobile {
        transform: translateX(calc(100% + uniWM(4)));
      }
    }
    svg {
      &:nth-child(1) {
        path {
          &.fill {
            fill: white;
          }
          &.stroke {
            stroke: white;
          }
        }
      }
      &:nth-child(2) {
        path {
          &.fill {
            fill: #4f5862;
          }
          &.stroke {
            stroke: #4f5862;
          }
        }
      }
    }
  }
  &::before {
    position: absolute;
    top: uniW(1);
    left: uniW(1);
    width: uniW(40);
    height: uniW(40);
    background: var(--colorWhite);
    border-radius: uniW(100);
    content: "";
    transition: transform 0.2s 0.2s var(--cubicBezier);
    @include mobile {
      top: uniWM(1);
      left: uniWM(1);
      width: uniWM(40);
      height: uniWM(40);
      border-radius: uniWM(100);
    }
  }
  svg {
    width: uniW(24);
    height: uniW(24);
    position: relative;
    z-index: 1;
    @include mobile {
      width: uniWM(24);
      height: uniWM(24);
    }
    path {
      transition: fill, stroke, 0.2s 0.2s var(--cubicBezier);
    }
  }
}
</style>
