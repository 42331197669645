<template>
  <SettingsExteriorPopover
    v-if="showSettings"
    :gallery="gallery"
    @color-selected="onChangeColor"
  />
  <button
    v-show="isShow"
    class="btn-light btn-settings icon grey"
    @click="onClickOpenSettings"
  >
    <template v-if="showSettings">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path
          d="M20 4L4 20M20 20L4 4L20 20Z"
          stroke="white"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        /></svg
      >СВЕРНУТЬ
    </template>
    <template v-else>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path
          d="M21 19H9M3 5h4-4Zm18 0H11h10ZM3 12h12H3Zm18 0h-2 2ZM3 19h2-2ZM9 7c1.1046 0 2-.8954 2-2s-.8954-2-2-2-2 .8954-2 2 .8954 2 2 2ZM17 14c1.1046 0 2-.8954 2-2s-.8954-2-2-2-2 .8954-2 2 .8954 2 2 2ZM7 21c1.1046 0 2-.8954 2-2s-.8954-2-2-2-2 .8954-2 2 .8954 2 2 2Z"
          stroke="var(--colorWhite)"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        /></svg
      >ЦВЕТ
    </template>
  </button>
</template>

<script>
import { PAGE_NAME_E_LARGUS, PAGE_NAME_VESTA_SW } from "@/data/constants";
import SettingsExteriorPopover from "@/components/SettingsExteriorPopover.vue";
import {
  gtmVestaSWCrossColour,
  gtmVestaSedanColour,
  gtmColorClick,
} from "@/utils/gtm";

export default {
  name: "SettingsExtorior",
  emits: ["colorSelected"],
  components: {
    SettingsExteriorPopover,
  },
  props: {
    gallery: Object,
  },
  watch: {
    isShow(val) {
      if (!val) {
        this.showSettings = false;
      }
    },
  },
  computed: {
    isShow() {
      const { mode } = this.$route.query;
      return mode === "interior" ? false : true;
    },
  },
  data() {
    return {
      showSettings: false,
    };
  },
  methods: {
    toggleSettings() {
      this.showSettings = !this.showSettings;

      if (this.showSettings) {
        const { name } = this.$route;
        if (name === PAGE_NAME_E_LARGUS) {
          gtmVestaSedanColour();
        } else if (name === PAGE_NAME_VESTA_SW) {
          gtmVestaSWCrossColour();
        }
      }
    },
    onClickOpenSettings() {
      this.toggleSettings();
    },
    onChangeColor(index) {
      this.$emit("colorSelected", index);
      gtmColorClick(this.gallery.colors[index].title);
      // this.toggleSettings();
    },
  },
};
</script>
