import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Maska from "maska";
import Vue3TouchEvents from "vue3-touch-events";
import { watchScreenOrientation } from "./utils/helpers";

watchScreenOrientation(store);

createApp(App)
  .use(store)
  .use(router)
  .use(Maska)
  .use(Vue3TouchEvents)
  .mount("#app");
