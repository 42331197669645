import { PAGE_NAME_VESTA_SW } from "@/data/constants";

export const PANORAMA = {
  options: {
    name: "interior-vesta-sw",
    items: [
      {
        src: require("@/assets/img/cars/vesta-sw/interior/light-q75.webp"),
        srcMobile: require("@/assets/img/cars/vesta-sw/interior/mobile/light-q25.webp"),
        startLookAt: 270,
      },
      {
        src: require("@/assets/img/cars/vesta-sw/interior/dark-q75.webp"),
        srcMobile: require("@/assets/img/cars/vesta-sw/interior/mobile/dark-q25.webp"),
        startLookAt: 270,
      },
    ],
  },
  infospots: [
    {
      position: {
        x: 2300,
        y: -1800,
        z: 4100,
      },
      title: "Новая обивка дверей дверные панели",
      desc: "Современный дизайн и удобный функционал.",
      media: { picture: require("@/assets/img/cars/vesta-sw/media/11.webp") },
      gtmReachGoal: "vesta-sw-cross_interior_pin-doors",
    },
    {
      position: {
        x: 4500,
        y: -950,
        z: 1800,
      },
      title: "Цифровая комбинация приборов",
      desc: "На экран можно вывести навигацию, информацию с мультимедиа или показания приборов.",
      media: { picture: require("@/assets/img/cars/vesta-sw/media/19.webp") },
      gtmReachGoal: "vesta-sw-cross_interior_pin-digitalcluster",
    },
    {
      position: {
        x: 4850,
        y: -750,
        z: 1000,
      },
      title: "Панель приборов с&nbsp;покрытием Soft Touch",
      desc: "Новый стиль и высокий уровень отделки.",
      media: { picture: require("@/assets/img/cars/vesta-sw/media/9.webp") },
      gtmReachGoal: "vesta-sw-cross_interior_pin-cluster",
    },
    {
      position: {
        x: 4600,
        y: -1700,
        z: 850,
      },
      title: 'Кнопка "Старт/Стоп"',
      desc: "Запуск двигателя осуществляется в одно касание.",
      media: { picture: require("@/assets/img/cars/vesta-sw/media/15.webp") },
      gtmReachGoal: "vesta-sw-cross_interior_pin-key",
    },
    {
      position: {
        x: 4800,
        y: -1500,
        z: 1250,
      },
      title: "РУЛЬ С ОБОГРЕВОМ",
      desc: "Руль с функцией обогрева приятно согревает руки водителя",
      media: { picture: require("@/assets/img/cars/vesta-sw/media/7.webp") },
      gtmReachGoal: "vesta-sw-cross_interior_pin-key",
    },
    {
      position: {
        x: 4850,
        y: -650,
        z: -190,
      },
      title: "Мультимедиа LADA Enjoy Vision Pro",
      desc: `
        <p>Поддержка сервисов Яндекса и&nbsp;возможность настройки систем автомобиля.</p>
        <p>Протестировать некоторые возможности вы&nbsp;можете</p>
        <p><button class="btn-enjoy" onclick="
            document.dispatchEvent(
              new CustomEvent('showEnjoy', { detail: { model: '${PAGE_NAME_VESTA_SW}' } })
            )
          ">Здесь</button></p>`,
      media: { picture: require("@/assets/img/cars/vesta-sw/media/18.webp") },
      gtmReachGoal: "vesta-sw-cross_interior_pin-multimedia",
    },
    {
      position: {
        x: 4600,
        y: -3500,
        z: -1800,
      },
      title: "ПОДСВЕТКА ФУНКЦИОНАЛЬНЫХ ЗОН",
      desc: "Создает уютную атмосферу.",
      media: { picture: require("@/assets/img/cars/vesta-sw/media/13.webp") },
      gtmReachGoal: "vesta-sw-cross_interior_pin-backlight",
    },
    {
      position: {
        x: 3500,
        y: -2500,
        z: -2650,
      },
      title: "Объемный звук",
      desc: "Настройки аудиосистемы созданы специально для LADA Vesta.",
      media: { picture: require("@/assets/img/cars/vesta-sw/media/14.webp") },
      gtmReachGoal: "vesta-sw-cross_interior_pin-sound",
    },
    {
      position: {
        x: 4400,
        y: 2400,
        z: -500,
      },
      title: "Новый плафон на потолке",
      desc: "Лаконичный стиль и интегрированный очечник с микролифтом.",
      media: { picture: require("@/assets/img/cars/vesta-sw/media/27.webp") },
      gtmReachGoal: "vesta-sw-cross_interior_pin-headlight",
    },
    {
      position: {
        x: 3000,
        y: 2800,
        z: 3000,
      },
      title: "Обивка потолка черного цвета",
      desc: "Стильная и практичная отделка.",
      media: { picture: require("@/assets/img/cars/vesta-sw/media/29.webp") },
      gtmReachGoal: "vesta-sw-cross_interior_pin-headblack",
    },
    {
      position: {
        x: 300,
        y: -4300,
        z: 2700,
      },
      title: "Сиденья с оптимальным профилем и боковой поддержкой",
      desc: "Удобны для людей разного телосложения.",
      media: { picture: require("@/assets/img/cars/vesta-sw/media/30.webp") },
      gtmReachGoal: "vesta-sw-cross_interior_pin-seat",
    },
    {
      position: {
        x: -500,
        y: -5000,
        z: -200,
      },
      title: "USB-розетка",
      desc: "Автомобиль всегда готов<br />подзарядить ваш гаджет",
      media: { picture: require("@/assets/img/cars/vesta-sw/media/20.webp") },
      gtmReachGoal: "vesta-sw-cross_interior_pin-usb",
    },
    {
      position: {
        x: -4200,
        y: -1750,
        z: 2000,
      },
      title: "Один из самых вместительных салонов в&nbsp;классе",
      desc: "Хорошие возможности для трансформации и&nbsp;перевозки грузов.",
      media: { picture: require("@/assets/img/cars/vesta-sw/media/25.webp") },
      gtmReachGoal: "vesta-sw-cross_interior_pin-capacity",
    },
    {
      position: {
        x: -4200,
        y: -1750,
        z: -2000,
      },
      title: "Обивка сидений",
      desc: "Новое сочетание цветов и фактур.",
      media: { picture: require("@/assets/img/cars/vesta-sw/media/22.webp") },
      gtmReachGoal: "vesta-sw-cross_interior_pin-seatcolors",
    },
    {
      position: {
        x: 3800,
        y: -4000,
        z: -100,
      },
      title: "ДВИГАТЕЛЬ 1,8 EVO И АВТОМАТИЧЕСКАЯ ТРАНСМИССИЯ",
      desc: "Обеспечивает высокий уровень комфорта и надежности",
      media: { picture: require("@/assets/img/cars/vesta-sw/media/34.webp") },
      gtmReachGoal: "vesta-sw-cross_interior_pin-transmission",
    },
  ],
};

export const GALLERY = {
  folder: "vesta-sw/exterior",
  colorActive: 0,
  colors: [
    {
      title: 'СЕРЕБРИСТЫЙ "ПЛАТИНА" (691) - МЕТАЛЛИК',
      hex: "#C3C4C4",
      folder: "grey",
      fileName: {
        light: "SWgrey_day00",
      },
    },
    {
      title: 'БЕЛЫЙ "ЛЕДНИКОВЫЙ" (221)',
      hex: "#FFFFFF",
      folder: "white",
      fileName: {
        light: "SWwhite_day00",
      },
    },
  ],
  breakpoints: [
    {
      slideIndex: 20, // 10
      items: [
        {
          offset: {
            top: "8vw",
            left: "-14vw",
          },
          offsetMobile: {
            top: "5",
            left: "-10",
          },
          title: "Передний бампер:<br />новое лицо Vesta ",
          desc: "Эмоциональный рельеф и&nbsp;интеграция с&nbsp;формой фар.",
          media: {
            picture: require("@/assets/img/cars/vesta-sw/media/3.webp"),
          },
          gtmReachGoal: "vesta-sw-cross-exterior-pin-frontbumper",
        },
        {
          offset: {
            top: "-2vw",
            left: "-7vw",
          },
          offsetMobile: {
            top: "-2",
            left: "-6",
          },
          title: "СВЕТОДИОДНАЯ ОПТИКА",
          desc: "Фары и задние фонари стали ярче, эффективней, современней.",
          media: {
            picture: require("@/assets/img/cars/vesta-sw/media/8.webp"),
          },
          gtmReachGoal: "vesta-sw-cross-exterior-pin-led",
        },
        {
          offset: {
            top: "-15vw",
            left: "0vw",
          },
          offsetMobile: {
            top: "-11",
            left: "0",
          },
          title: "Крыша контрастного черного цвета",
          desc: "Стильная и запоминающаяся деталь.",
          media: {
            picture: require("@/assets/img/cars/vesta-sw/media/10.webp"),
          },
          gtmReachGoal: "vesta-sw-cross-exterior-pin-black",
        },
      ],
    },
    {
      slideIndex: 80, // 70
      items: [
        {
          offset: {
            top: "4vw",
            left: "-20vw",
          },
          offsetMobile: {
            top: "2",
            left: "-14",
          },
          title: "НОВЫЙ ДИЗАЙН ЛИТЫХ ДИСКОВ:",
          desc: "оригинальный рисунок спиц подчеркивает динамику автомобиля.",
          media: {
            picture: require("@/assets/img/cars/vesta-sw/media/5.webp"),
          },
          gtmReachGoal: "vesta-sw-cross-exterior-pin-disks",
        },
        {
          offset: {
            top: "-15vw",
            left: "20vw",
          },
          offsetMobile: {
            top: "-11",
            left: "11",
          },
          title: "Антенна<br>«Акулий плавник»",
          desc: "Аэродинамически выверенная деталь.",
          media: {
            picture: require("@/assets/img/cars/vesta-sw/media/6.webp"),
          },
          gtmReachGoal: "vesta-sw-cross-exterior-pin-fin",
        },
        {
          offset: {
            top: "7vw",
            left: "15vw",
          },
          offsetMobile: {
            top: "3.5",
            left: "8",
          },
          title: "203 миллиметра над дорогой",
          desc: "Высокий клиренс позволяет преодолевать неровности городского пейзажа и&nbsp;уверенно съезжать с&nbsp;асфальта.",
          media: {
            picture: require("@/assets/img/cars/vesta-sw/media/33.webp"),
          },
          gtmReachGoal: "vesta-sw-cross-exterior-pin-clearance",
        },
      ],
    },
    {
      slideIndex: 200, // 190
      items: [
        {
          offset: {
            top: "6vw",
            left: "-15vw",
          },
          offsetMobile: {
            top: "3",
            left: "-11",
          },
          title: "Спортивный дизайн заднего бампера",
          desc: "Дополняет динамичный облик LADA Vesta.",
          media: {
            picture: require("@/assets/img/cars/vesta-sw/media/4.webp"),
          },
          gtmReachGoal: "vesta-sw-cross-exterior-pin-rearbumper",
        },
        {
          offset: {
            top: "-3vw",
            left: "-17vw",
          },
          offsetMobile: {
            top: "-3",
            left: "-13",
          },
          title: "Светодиодные фонари и подсветка номера",
          desc: "LED-фонари повышают безопасность и&nbsp;снижают энергопотребление.",
          media: {
            picture: require("@/assets/img/cars/vesta-sw/media/32.webp"),
          },
          gtmReachGoal: "vesta-sw-cross-exterior-pin-number",
        },
        {
          offset: {
            top: "4vw",
            left: "-25vw",
          },
          offsetMobile: {
            top: "1",
            left: "-18",
          },
          title: "Парктроник",
          desc: "Сенсоры расположены в&nbsp;заднем бампере.",
          media: {
            picture: require("@/assets/img/cars/vesta-sw/media/2.webp"),
          },
          gtmReachGoal: "vesta-sw-cross-exterior-pin-parking",
        },
        {
          offset: {
            top: "-7vw",
            left: "-15vw",
          },
          offsetMobile: {
            top: "-7",
            left: "-10",
          },
          title: "Вместительный багажник",
          desc: "Большой объем для габаритных грузов и&nbsp;специальные ниши для нужных и&nbsp;полезных мелочей.",
          media: {
            picture: require("@/assets/img/cars/vesta-sw/media/31.webp"),
          },
          gtmReachGoal: "vesta-sw-cross-exterior-pin-luggagecarrier",
        },
      ],
    },
  ],
};
