<template>
  <transition name="fade-modal">
    <div v-if="popoverModalShow" class="modal modal_type_dark">
      <div class="modal-bg" @click="hidePopoverModal"></div>
      <div class="modal-close" @click="hidePopoverModal">
        <svg width="34" height="34" viewBox="0 0 34 34" fill="none">
          <path
            d="m17 16.9997-5.6569-5.6568m0 11.3137L17 16.9997l-5.6569 5.6569ZM17 16.9997l5.6569-5.6568L17 16.9997Zm0 0 5.6569 5.6569L17 16.9997Z"
            stroke="#5F6368"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
      <div class="modal-content">
        <div class="popover-contet-mobile">
          <div
            v-if="media && media.video"
            :data-video="mediaString"
            class="popover__media popover__media_type_video"
            onclick="
              document.dispatchEvent(
                new CustomEvent('showVideo', { detail: { media: this.getAttribute('data-video') } })
              )
            "
            @click="$emit('showVideo')"
          >
            <img :src="media.picture" alt="" />
          </div>
          <div v-else-if="media" class="popover__media">
            <img :src="media.picture" alt="" />
          </div>
          <div v-if="title" class="popover__title" v-html="title"></div>
          <div v-if="desc" class="popover__desc" v-html="desc"></div>
        </div>
        <div class="modal-foot">
          <button class="btn-close" @click="hidePopoverModal"></button>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapState, mapMutations } from "vuex";

export default {
  name: "PopoverModalComponent",
  computed: {
    ...mapState(["popoverModalShow", "popoverModalContent"]),
    media() {
      return this.popoverModalContent?.media;
    },
    mediaString() {
      return JSON.stringify(this.media);
    },
    title() {
      return this.popoverModalContent?.title;
    },
    desc() {
      return this.popoverModalContent?.desc;
    },
  },
  methods: {
    ...mapMutations(["hidePopoverModal"]),
  },
};
</script>
