<template>
  <section v-if="step === 1">
    <img src="@/assets/img/enjoy/settings-auto-menu.webp" />
    <highlight-button
      @click-button="step = 2"
      style="width: 64%; height: 9%; top: 12%; left: 2%"
    />
    <highlight-button
      @click-button="$emit('change', 'settings')"
      style="width: 19%; height: 6%; top: 3.5%; left: 2%"
    />
  </section>
  <section v-else-if="step === 2">
    <img :src="require(`@/assets/img/enjoy/parking-${parking}.webp`)" />
    <highlight-button
      @click-button="step = 1"
      style="width: 19%; height: 6%; top: 3.5%; left: 2%"
    />
  </section>
</template>

<script>
import HighlightButton from "../HighlightButton.vue";
export default {
  components: { HighlightButton },
  name: "EnjoySettingsAuto",
  data() {
    return {
      step: 1,
      lit: "off",
      parking: 1,
      timerId: 0,
    };
  },
  unmounted() {
    if (this.timerId) clearInterval(this.timerId);
  },
  watch: {
    step(val) {
      if (val === 2) {
        this.timerId = setInterval(() => {
          this.parking = this.parking == 1 ? 2 : 1;
        }, 2000);
      } else if (val !== 2 && this.timerId) {
        clearInterval(this.timerId);
      }
    },
  },
  methods: {
    onLit() {
      if (this.lit === "off") this.lit = "on";
      else this.lit = "off";
    },
  },
};
</script>

<style lang="scss" scoped>
section {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  > img,
  > video {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: 50% 50%;
  }
}
</style>
