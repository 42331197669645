<template>
  <section>
    <img src="@/assets/img/enjoy/menu.webp" alt="" />
    <highlight-button
      @click-button="$emit('change', 'location')"
      style="top: 13%; left: 4%; width: 18.1%; height: 14%"
    />
    <highlight-button
      @click-button="$emit('change', 'music')"
      style="top: 13%; left: 26.5%; width: 18.1%; height: 14%"
    />
    <highlight-button
      @click-button="$emit('change', 'settings')"
      style="top: 13%; left: 49%; width: 18.1%; height: 14%"
    />
    <highlight-button
      @click-button="$emit('change', 'phone')"
      style="top: 13%; left: 71.3%; width: 18.1%; height: 14%"
    />
  </section>
</template>

<script>
import HighlightButton from "../HighlightButton.vue";
export default {
  components: { HighlightButton },
  name: "EnjoyMenu",
};
</script>

<style lang="scss" scoped>
section {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  > img,
  > video {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: 50% 50%;
  }
}
</style>
