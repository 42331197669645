export const THEMES = ["light", "dark"];

export const PLACEHOLDERS = ["two_cars", "three_cars"];

export const GALLERY_STEP_DEGREE = 20; // исходники содержут 360 кадров, в переменной указываем кол-во градусов поворота
export const GALLERY_TRANSITION = 500; // длительность анимации скрытие/показ пинов
export const GALLERY_TICK = 50; // кол-во мс при прокуртки
export const GALLERY_ROOT_FOLDER = "/static/cars/"; // корневая папка с исходниками
export const GALLERY_BEGIN = 0; // первый кадр
export const GALLERY_END = 360; // всего кадров

export const PAGE_NAME_CINEMA = "cinema";
export const PAGE_NAME_E_LARGUS = "e-largus";
export const PAGE_NAME_AURA = "aura";
export const PAGE_NAME_VESTA_SE_PLACEHOLDER = "vesta-se-placeholder";
export const PAGE_NAME_VESTA_SW = "vesta-sw";
export const PAGE_NAME_VESTA_SW_PLACEHOLDER = "vesta-sw-placeholder";
export const PAGE_NAME_FAW_PLACEHOLDER = "faw-placeholder";

// список моделей машин в форме обратной связи
export const FEEDBACK_MODEL_LIST = [
  {
    id: 15071295,
    name: "LADA Aura",
  },
  {
    id: 15071213,
    name: "LADA Vesta Седан",
  },
  {
    id: 15071215,
    name: "LADA Vesta Cross",
  },
  {
    id: 15071217,
    name: "LADA Vesta SW",
  },
  {
    id: 15071218,
    name: "LADA Vesta SW Cross",
  },
];
