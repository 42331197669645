<template>
  <transition name="fade">
    <div
      v-show="isShow"
      class="popover-core"
      :class="{ popover_state_show: show }"
      @mouseleave="onHide"
      @mouseenter="onShow"
      @click="onClick"
    >
      <div class="popover-point-pulse">
        <span></span>
      </div>
      <Popover
        class="popover_type_overflow"
        :media="media"
        :title="title"
        :desc="desc"
        @showVideo="onHide"
      />
    </div>
  </transition>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import Popover from "@/components/Popover.vue";
import { gtmEvent } from "@/utils/gtm";

export default {
  name: "GalleryPopover",
  components: {
    Popover,
  },
  props: {
    top: String,
    left: String,
    topMobile: String,
    leftMobile: String,
    title: String,
    desc: String,
    media: Object,
    gtmReachGoal: String,
    currentBreakpointIndex: Number,
    breakpointIndex: Number,
    hideAll: Boolean,
  },
  computed: {
    ...mapState(["isMobile"]),
    isShow() {
      const isCurrentBreakpoint =
        this.currentBreakpointIndex === this.breakpointIndex;
      return !this.hideAll && isCurrentBreakpoint;
    },
  },
  data() {
    return {
      show: false,
    };
  },
  methods: {
    ...mapMutations([
      "popoverModalShow",
      "showPopoverModal",
      "setPopoverContent",
    ]),
    onShow() {
      if (this.isMobile) return;

      this.show = true;
      this.$emit("show", true);
      if (this.gtmReachGoal) {
        gtmEvent(this.gtmReachGoal);
      }
    },
    onHide() {
      if (this.isMobile) return;

      this.show = false;
      this.$emit("show", false);
    },
    onClick() {
      if (!this.isMobile) return;

      this.showPopoverModal();
      this.setPopoverContent({
        title: this.title,
        desc: this.desc,
        media: this.media,
      });
      if (this.gtmReachGoal) {
        gtmEvent(this.gtmReachGoal);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.popover-core {
  top: calc(50% + v-bind(top));
  left: calc(50% + v-bind(left));
  position: absolute;
  visibility: visible;
  z-index: 2000;
  @include mobile {
    top: calc(50% + v-bind(topMobile) * 1vh);
    top: calc(50% + var(--vh, 1vh) * v-bind(topMobile));
    left: calc(50% + v-bind(leftMobile) * 1vh);
    left: calc(50% + var(--vh, 1vh) * v-bind(leftMobile));
  }
  &.popover_state_show {
    z-index: 3100;
  }
  .popover {
    // pointer-events: none;
    visibility: hidden;
    margin-left: uniH(20);
  }
  &.popover_state_show .popover {
    visibility: visible;
  }
}
.popover-core {
  &:hover {
    .popover-point-pulse {
      opacity: 1;
      &::before {
        visibility: visible;
        animation: pulse-ring 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) both;
      }
      span {
        animation: pulse-dot 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s
          both;
      }
    }
  }
}
.popover-point-pulse {
  // transform: translateX(-50%) translateY(-50%);
  width: uniH(24);
  height: uniH(24);
  position: relative;
  // cursor: pointer;
  transition: var(--transitionTime) var(--cubicBezier);
  // z-index: 100;
  opacity: 0.87;
  @include mobile {
    width: uniHM(18);
    height: uniHM(18);
  }
  &:hover {
    &::before {
      visibility: visible;
      animation-play-state: running;
    }
    span {
      animation-play-state: running;
    }
  }
  &:before {
    content: "";
    position: absolute;
    display: block;
    width: 250%;
    height: 250%;
    box-sizing: border-box;
    margin-left: -75%;
    margin-top: -75%;
    border-radius: uniH(90);
    border: uniH(2) dotted rgba(255, 255, 255, 0.87);
    pointer-events: none;
    visibility: hidden;
    @include mobile {
      border-radius: uniHM(90);
      border-width: uniHM(2);
    }
  }
  span {
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.87);
    border-radius: uniH(30);
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml,%3Csvg width='19' height='19' viewBox='0 0 19 19' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.4997 9.5002H1.833m7.6667 7.6666V9.5002v7.6666Zm0-7.6666V1.8335v7.6667Zm0 0h7.6666-7.6666Z' stroke='%23000' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
    background-position: 50% 50%;
    background-size: uniH(13);
    pointer-events: none;
    @include mobile {
      border-radius: uniHM(30);
      background-size: uniHM(10);
    }
    &:before {
      position: absolute;
      top: 50%;
      left: 50%;
      width: calc(100% + uniH(8));
      height: calc(100% + uniH(8));
      transform: translate3d(-50%, -50%, 0);
      border: uniH(1) solid rgba(255, 255, 255, 0.87);
      border-radius: uniH(60);
      content: "";
      @include mobile {
        width: calc(100% + uniHM(8));
        height: calc(100% + uniHM(8));
        border-width: uniHM(1);
        border-radius: uniHM(60);
      }
    }
  }
  &:after {
    content: "";
  }
}

@keyframes pulse-ring {
  0% {
    transform: scale(0.6);
  }
  80%,
  100% {
    opacity: 0;
  }
}

@keyframes pulse-dot {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1.2);
    background-image: url("data:image/svg+xml,%3Csvg width='23' height='23' viewBox='0 0 23 23' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.5 11.5002H1.8333M11.5 21.1668v-9.6666 9.6666Zm0-9.6666V1.8335v9.6667Zm0 0h9.6667H11.5Z' stroke='%235F6368' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
  }
}
@include mobile {
  @keyframes pulse-dot {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(0.9);
    }
    100% {
      transform: scale(1.2);
    }
  }
}

.fade-enter-active {
  transition: all 0.5s var(--cubicBezier);
}
.fade-leave-active {
  transition: all 0.3s var(--cubicBezier);
}

.fade-enter-from,
.fade-leave-to {
  transform: scale(0);
}
</style>
