<template>
  <HeaderCar />
  <Car :gallery="gallery" :panorama="panorama" />
  <Footer>
    <template #content>
      <div class="footer__title">LADA</div>
      <div class="footer__name">VESTA SW CROSS</div>
    </template>
    <template #btn-right>
      <SettingsExterior :gallery="gallery" @color-selected="onChangeColor" />
    </template>
  </Footer>
  <MenuMobile :colorActive="gallery.colorActive">
    <template #controls>
      <ButtonSwitcher />
    </template>
    <template #main>
      <SettingsExteriorPopover
        :gallery="gallery"
        @color-selected="onChangeColor"
      >
      </SettingsExteriorPopover>
    </template>
  </MenuMobile>
</template>

<script>
import { PANORAMA, GALLERY } from "@/data/faw-placeholder";
import HeaderCar from "@/components/HeaderCar.vue";
import Car from "@/components/Car.vue";
import ButtonSwitcher from "@/components/ButtonSwitcher.vue";
import SettingsExterior from "@/components/SettingsExterior.vue";
import SettingsExteriorPopover from "@/components/SettingsExteriorPopover.vue";
import Footer from "@/components/Footer.vue";
import MenuMobile from "@/components/MenuMobile.vue";

export default {
  name: "VestaSWView",
  components: {
    HeaderCar,
    Car,
    ButtonSwitcher,
    SettingsExterior,
    SettingsExteriorPopover,
    Footer,
    MenuMobile,
  },
  data() {
    return {
      panorama: PANORAMA,
      gallery: GALLERY,
    };
  },
  methods: {
    onChangeColor(color) {
      this.gallery.colorActive = color;
    },
  },
};
</script>
