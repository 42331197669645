<template>
  <section>
    <img src="@/assets/img/enjoy/alice.webp" />
    <div class="alice-buttons">
      <img
        :class="{ show: buttons.includes(1) }"
        src="@/assets/img/enjoy/alice-1.webp"
      />
      <img
        :class="{ show: buttons.includes(2) }"
        src="@/assets/img/enjoy/alice-2.webp"
      />
      <img
        :class="{ show: buttons.includes(3) }"
        src="@/assets/img/enjoy/alice-3.webp"
      />
    </div>
  </section>
</template>

<script>
export default {
  name: "EnjoyAlice",
  data() {
    return {
      buttons: [],
      timerId: 0,
      number: 1,
    };
  },
  mounted() {
    this.timerId = setInterval(() => {
      this.buttons.push(this.number);
      this.number++;
    }, 1000);
  },
  watch: {
    number(val) {
      if (val == 4) {
        clearInterval(this.timerId);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
section {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  > img,
  > video {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: 50% 50%;
  }
}
.video {
  overflow: hidden;
  height: 100%;
  video {
    top: -10%;
    position: relative;
    width: 100%;
    position: relative;
  }
}
.bg {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0px;
  bottom: 0;
}
.alice-buttons {
  position: absolute;
  width: 100%;
  height: auto;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 2%;
  bottom: 12%;
  padding: 0 10px;
  img {
    display: block;
    width: inherit;
    opacity: 0;
    transition: opacity 0.2s;
    &.show {
      opacity: 1;
    }
  }
}
</style>
