<template>
  <div
    ref="panoramaMain"
    class="panorama-container panorama-main"
    :class="{ show: $route.name === 'home' }"
  ></div>
  <div
    class="popover-backdrop popover-backdrop_type_inactive popover-backdrop_type_light"
    :class="{ popover_state_show: pinActive }"
  ></div>
  <div style="display: none">
    <div class="popover-core popover-core_type_inactive popover_id_main_left3">
      <div
        class="popover-point popover-point_type_eye popover-point_size_main"
      ></div>
      <Popover class="popover_type_main" title="FAW PLACEHOLDER" />
    </div>

    <div class="popover-core popover-core_type_inactive popover_id_main_left2">
      <div
        class="popover-point popover-point_type_eye popover-point_size_main"
      ></div>
      <Popover class="popover_type_main" title="VESTA СЕДАН PLACEHOLDER" />
    </div>

    <div class="popover-core popover-core_type_inactive popover_id_main_left1">
      <div
        class="popover-point popover-point_type_eye popover-point_size_main"
      ></div>
      <Popover class="popover_type_main" title="e-Largus" />
    </div>

    <video
      muted
      loop
      class="cinema-video"
      id="video"
      src="@/assets/video/cinema.mp4"
    ></video>

    <div
      class="popover-core popover-core_type_inactive popover_id_main_center button-cinema"
    >
      <div
        class="popover-point popover-point_type_eye popover-point_size_main"
      ></div>
      <Popover class="popover_type_main" title="AURA" />
    </div>

    <div
      class="popover-core popover-core_type_inactive popover_id_main_right1 button-vesta-sw-cross"
    >
      <div
        class="popover-point popover-point_type_eye popover-point_size_main"
      ></div>
      <Popover class="popover_type_main" title="VESTA<br/>SW CROSS" />
    </div>
  </div>
</template>

<script>
import * as THREE from "three";
import * as PANOLENS from "@/libs/panolens/panolens.js";
import { PANORAMA_LIST, INFOSPOT_LIST1, INFOSPOT_LIST2 } from "@/data/hall";
import { mapState } from "vuex";
import {
  PLACEHOLDERS,
  PAGE_NAME_CINEMA,
  PAGE_NAME_VESTA_SW,
  PAGE_NAME_AURA,
} from "@/data/constants";
import { degToRad, updatePlaceholder } from "@/utils/helpers";
import Popover from "@/components/Popover.vue";
import { gtmCinema, gtmVestaSWCross, gtmAura } from "@/utils/gtm";

const markerImage = require("@/assets/img/icons/marker-eye.svg");
const markerImageDark = require("@/assets/img/icons/marker-eye-dark.svg");

export default {
  name: "PanoramaMain",
  components: {
    Popover,
  },
  data() {
    return {
      panorama: [],
      viewer: null,
      cameraStartPosition: {},
      pinActive: false,
      INFOSPOT_LIST: [],
    };
  },
  computed: {
    ...mapState(["placeholder", "loading", "isMobile", "feedbackModalShow"]),
  },
  watch: {
    loading() {
      this.init();
    },
    feedbackModalShow(state) {
      state ? this.disableKeys() : this.enableKeys();
    },
    pinActive(state) {
      state ? this.disableKeys() : this.enableKeys();
    },
    placeholder(placeholder) {
      updatePlaceholder(placeholder, this.changePlaceholder);
    },
  },
  methods: {
    init() {
      if (this.viewer) return false;
      this.setupViewer();

      PANORAMA_LIST.forEach((options) => {
        const source = this.isMobile ? options.srcMobile : options.src;
        const panorama = this.addPanorama(source);
        this.startLookAt(panorama, options.startLookAt);
      });

      this.addMarkers();
      this.addVideo();
    },
    addVideo() {
      const video = document.querySelector("#video");
      video.play();

      const videoTexture = new THREE.VideoTexture(video);
      const videoMaterial = new THREE.MeshBasicMaterial({
        map: videoTexture,
      });

      const cinemaGeometry = new THREE.PlaneGeometry(2690, 1360);

      const videoObject = new THREE.Mesh(cinemaGeometry, videoMaterial);

      videoObject.rotation.set(0, Math.PI / 2, 0);
      videoObject.position.set(-4500, 870, 0);

      const borderMaterial = new THREE.MeshStandardMaterial({
        color: 0x808080,
        metalness: 1,
      });

      // TO-DO: Переделать в конструктор

      const borderGeometry1 = new THREE.PlaneGeometry(2690, 30);
      const borderObject1 = new THREE.Mesh(borderGeometry1, borderMaterial);

      borderObject1.rotation.set(0, Math.PI / 2, 0);
      borderObject1.position.set(-4490, 200, 0);

      const borderGeometry2 = new THREE.PlaneGeometry(2690, 30);
      const borderObject2 = new THREE.Mesh(borderGeometry2, borderMaterial);

      borderObject2.rotation.set(0, Math.PI / 2, 0);
      borderObject2.position.set(-4490, 1530, 0);

      const borderGeometry3 = new THREE.PlaneGeometry(30, 1340);
      const borderObject3 = new THREE.Mesh(borderGeometry3, borderMaterial);

      borderObject3.rotation.set(0, Math.PI / 2, 0);
      borderObject3.position.set(-4490, 870, 1330);

      const borderGeometry4 = new THREE.PlaneGeometry(30, 1340);
      const borderObject4 = new THREE.Mesh(borderGeometry4, borderMaterial);

      borderObject4.rotation.set(0, Math.PI / 2, 0);
      borderObject4.position.set(-4490, 870, -1330);

      const cinemaGroup = new THREE.Group();

      const ambientLight = new THREE.AmbientLight(0xffffff, 0.7);
      const pointLight = new THREE.PointLight(0xffffff, 0.2);

      cinemaGroup.add(ambientLight);
      cinemaGroup.add(pointLight);
      cinemaGroup.add(videoObject);
      cinemaGroup.add(borderObject1);
      cinemaGroup.add(borderObject2);
      cinemaGroup.add(borderObject3);
      cinemaGroup.add(borderObject4);

      this.panorama[0].add(cinemaGroup);
      this.panorama[1].add(cinemaGroup);
    },
    disableKeys() {
      this.viewer.disableKeys();
    },
    enableKeys() {
      this.viewer.enableKeys();
    },
    startLookAt(panorama, deg, axios = "y") {
      const angle = degToRad(deg);

      panorama.rotation[axios] = angle;
    },
    addPanorama(url) {
      const panorama = new PANOLENS.ImagePanorama(url);
      this.viewer.add(panorama);
      this.panorama.push(panorama);

      return panorama;
    },
    setPanorama(panorama) {
      this.viewer.setPanorama(panorama);
    },
    setCameraPosition(x, y, z) {
      this.viewer.camera.position.x = x ? x : this.viewer.camera.position.x;
      this.viewer.camera.position.y = y ? y : this.viewer.camera.position.y;
      this.viewer.camera.position.z = z ? z : this.viewer.camera.position.z;
    },
    changePlaceholder(placeholder) {
      if (placeholder === PLACEHOLDERS[0]) {
        this.setPanorama(this.panorama[0]);
        return;
      }

      if (placeholder === PLACEHOLDERS[1]) {
        this.setPanorama(this.panorama[1]);
        return;
      }

      this.setPanorama(this.panorama[2]);
    },
    getParams() {
      const urlParams = new URLSearchParams(location.search);
      const fov = urlParams.get("fov");
      const posZ = urlParams.get("posZ");
      return { fov, posZ };
    },
    setupViewer() {
      const defaultFov = this.isMobile ? 80 : 43;
      const defaultCameraPositionZ = this.isMobile ? 500 : 1500;

      const container = this.$refs.panoramaMain;
      const { fov, posZ } = this.getParams();
      const cameraFov = fov || defaultFov;
      const cameraPositionZ = posZ || defaultCameraPositionZ;
      const coofAxisX = this.isMobile ? 3 : 5;
      const coofAxisYTop = 2.5;
      const coofAxisYBottom = 3.5;

      this.viewer = new PANOLENS.Viewer({
        container: container,
        cameraFov: cameraFov,
        controlBar: false,
        output: "console",
        autoHideInfospot: false,
        name: "main",
      });

      if (cameraPositionZ > 0) {
        this.viewer.camera.position.z = cameraPositionZ;
      }

      // horizontal
      this.viewer.OrbitControls.minAzimuthAngle = -Math.PI / coofAxisX;
      this.viewer.OrbitControls.maxAzimuthAngle = Math.PI / coofAxisX;

      // vertical
      this.viewer.OrbitControls.minPolarAngle = Math.PI / coofAxisYTop;
      this.viewer.OrbitControls.maxPolarAngle = (Math.PI * 2) / coofAxisYBottom;

      this.viewer.OrbitControls.noZoom = true;
    },
    setCameraStartPosition(vector3) {
      this.cameraStartPosition = Object.assign({}, vector3);
    },
    addMarkers() {
      this.panorama.forEach((panorama, index) => {
        if (this.panorama[0] === panorama) {
          this.INFOSPOT_LIST = INFOSPOT_LIST2;
          this.INFOSPOT_LIST.forEach((options) => {
            this.addMarker(panorama, options, index);
          });
        }
        if (this.panorama[1] === panorama) {
          this.INFOSPOT_LIST = [...INFOSPOT_LIST1, ...INFOSPOT_LIST2];
          this.INFOSPOT_LIST.forEach((options) => {
            this.addMarker(panorama, options, index);
          });
        }
      });
    },
    addMarker(panorama, options, panoramaIndex) {
      const { position, size, sizeMobile, className, nextPage, areaHover } =
        options;
      const scale = this.isMobile ? sizeMobile : size;
      const elHover = document.getElementsByClassName(className)[0];
      const marker = panoramaIndex === 1 ? markerImageDark : markerImage;
      const infospot = new PANOLENS.Infospot(scale, marker, false);

      infospot.position.set(position.x, position.y, position.z);
      infospot.addHoverElement(elHover, 0);
      panorama.add(infospot);

      infospot.addEventListener("click", this.onClick(nextPage));
      infospot.addEventListener("hoverenter", this.onHoverEnter());
      infospot.addEventListener("hoverleave", this.onHoverLeave());

      if (areaHover) {
        this.addArea(panorama, infospot, options);
      }
    },
    addArea(panorama, infospot, options) {
      const {
        areaHover: { position, size, rotate },
        nextPage,
      } = options;
      const geometry = new THREE.PlaneGeometry(size.width, size.height);
      const material = new THREE.MeshBasicMaterial({
        color: 0x000000,
        opacity: 0,
        transparent: true,
      });
      const plane = new THREE.Mesh(geometry, material);

      plane.position.set(position.x, position.y, position.z);
      plane.rotation.y = THREE.Math.degToRad(rotate.y);

      plane.addEventListener("click", this.onClick(nextPage, infospot));
      plane.addEventListener("hoverenter", this.onHoverEnter(infospot));
      plane.addEventListener("hoverleave", this.onHoverLeave(infospot));

      panorama.add(plane);
    },
    onClick(nextPage, infospot) {
      return (e) => {
        if (infospot) {
          infospot.onHoverEnd(e);
          this.onHoverLeave(infospot)(e);
        } else {
          this.onHoverLeave()(e);
        }
        this.pinActive = false;
        this.toNext(nextPage);
      };
    },
    onHoverEnter(infospot) {
      return (e) => {
        if (this.isMobile) return true;
        if (infospot) {
          infospot.onHoverStart(e);
          infospot.element.classList.add("popover_state_show");
        } else {
          e.target.element.classList.add("popover_state_show");
        }
        this.pinActive = true;
      };
    },
    onHoverLeave(infospot) {
      return (e) => {
        if (this.isMobile) return true;
        if (infospot) {
          infospot.onHoverEnd(e);
          infospot.element.classList.remove("popover_state_show");
        } else {
          e.target.element.classList.remove("popover_state_show");
        }
        this.pinActive = false;
      };
    },
    toNext(pageName) {
      this.$router.push({ name: pageName });
      if (pageName === PAGE_NAME_CINEMA) {
        gtmCinema();
      } else if (pageName === PAGE_NAME_AURA) {
        gtmAura();
      } else if (pageName === PAGE_NAME_VESTA_SW) {
        gtmVestaSWCross();
      }
    },
  },
};
</script>

<style>
.cinema-video {
  pointer-events: none;
  display: none;
}
</style>
