<template>
  <div class="popover">
    <div class="popover__inner">
      <div class="popover__body">
        <div
          v-if="media && media.video"
          :data-video="mediaString"
          class="popover__media popover__media_type_video"
          onclick="
            document.dispatchEvent(
              new CustomEvent('showVideo', { detail: { media: this.getAttribute('data-video') } })
            )
          "
          @click="$emit('showVideo')"
        >
          <img :src="media.picture" alt="" />
        </div>
        <div v-else-if="media" class="popover__media">
          <img :src="media.picture" alt="" />
        </div>
        <div v-if="title" class="popover__title" v-html="title"></div>
        <div v-if="desc" class="popover__desc" v-html="desc"></div>
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PopoverComponent",
  props: {
    title: String,
    desc: String,
    media: Object,
  },
  computed: {
    mediaString() {
      return JSON.stringify(this.media);
    },
  },
};
</script>
