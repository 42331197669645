import { createStore } from "vuex";
import { THEMES, PLACEHOLDERS } from "@/data/constants";
import {
  isMobile,
  isPortrait,
  getParamsCity,
  getParamsDealer,
} from "@/utils/helpers";

import * as getters from "./getters";
import * as actions from "./actions";
import * as mutations from "./mutations";

export default createStore({
  state: {
    theme: THEMES[0],
    loading: true,
    isMobile: isMobile(),
    isPortrait: isPortrait(),
    paramsCity: getParamsCity(),
    paramsDealer: getParamsDealer(),
    videoModalMedia: null,
    videoModalShow: false,
    enjoyModalModel: null,
    enjoyModalShow: false,
    tutorialModalShow: false,
    welcomeModalShow: false,
    feedbackModalShow: false,
    popoverModalContent: {},
    popoverModalShow: false,
    whiteThemeEnjoy: false,
    highlight: true,
    placeholder: PLACEHOLDERS[0],
  },
  getters,
  actions,
  mutations,
});
