<template>
  <div>
    <highlight-button
      v-show="current !== 'main'"
      @click-button="change('main')"
      style="top: 92%; left: 2%; width: 9.1%; height: 7%"
    />
    <highlight-button
      v-show="current !== 'location'"
      @click-button="change('location')"
      style="top: 92%; left: 16.4%; width: 9.1%; height: 7%"
    />
    <highlight-button
      v-show="current !== 'music'"
      @click-button="change('music')"
      style="top: 92%; left: 30.2%; width: 9.1%; height: 7%"
    />
    <highlight-button
      v-show="current !== 'alice'"
      @click-button="change('alice')"
      style="top: 92%; left: 45.5%; width: 9.1%; height: 7%"
    />
    <highlight-button
      v-show="
        current !== 'settings' &&
        current !== 'settings-sound' &&
        current !== 'settings-auto' &&
        current !== 'phone'
      "
      @click-button="change('settings')"
      style="top: 92%; left: 60.7%; width: 9.1%; height: 7%"
    />
    <highlight-button
      v-show="current !== 'menu'"
      @click-button="change('menu')"
      style="top: 92%; left: 74.8%; width: 9.1%; height: 7%"
    />
  </div>
</template>

<script>
import HighlightButton from "./HighlightButton.vue";
export default {
  name: "EnjoyControls",
  components: {
    HighlightButton,
  },
  props: {
    current: String,
  },
  methods: {
    change(value) {
      this.$emit("change", value);
    },
  },
};
</script>

<style lang="scss" scoped>
.nav-bar {
  position: absolute;
  width: 100%;
  height: auto;
  bottom: -1%;
  left: 0;
  &-active {
    position: absolute;
    // width: 9%;
    height: 100%;
    bottom: 0%;
    left: 0;
    img.bar {
      height: 100%;
      opacity: 0.3;
    }
  }
  img.bar {
    width: 100%;
  }
  .img-active {
    position: absolute;
    height: 100%;
  }
}
</style>
