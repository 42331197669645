/* eslint-disable no-undef */

export function gtmEvent(value) {
  ym(29134875, "reachGoal", value);
}

/**
 * Установить событие на факт 100% загрузки экрана
 */
export function gtmScreenLoading() {
  // return ym(88657503, "reachGoal", "ScreenLoading");
}
/**
 * 3.1. Нажатие кнопки со стрелкой вправо
 */
export function gtmNavigationRight() {
  // return ym(88657503, "reachGoal", "Navigation_Right");
}
/**
 * 3.2. Нажатие кнопки со стрелкой влево
 */
export function gtmNavigationLeft() {
  // return ym(88657503, "reachGoal", "Navigation_Left");
}
/**
 * 3.3. Нажатие кнопки Пропустить
 */
export function gtmNavigationSkip() {
  // return ym(88657503, "reachGoal", "Navigation_Skip");
}
/**
 * 3.4. Нажатие крестика справа для закрытия
 */
export function gtmNavigationClose() {
  // return ym(88657503, "reachGoal", "Navigation_Close");
}
/**
 * 3.5. Нажатие кнопки Информация
 */
export function gtmNavigationInfo() {
  return ym(29134875, "reachGoal", "click_information_cars_showroom");
}
/**
 * 4.1. Нажатие кнопки Ночь
 */
export function gtmNight() {
  return ym(29134875, "reachGoal", "click_night_cars_showroom");
}
/**
 * 4.2. Нажатие кнопки День
 */
export function gtmDay() {
  return ym(29134875, "reachGoal", "click_day_cars_showroom");
}
/**
 * 4.3. Нажатие блока Конфигуратор
 */
export function gtmConfigurator() {
  return ym(29134875, "reachGoal", "click_configurator_cars_showroom");
}
/**
 * 4.4. Нажатие кнопки Кинотеатр
 */
export function gtmCinema() {
  return ym(29134875, "reachGoal", "click_cinema_cars_showroom");
}
/**
 * 4.4.1. Нажатие кнопки Play в разделе Кинотеатр
 */
export function gtmCinemaPlay() {
  return ym(29134875, "reachGoal", "click_play_video_cars_showroom");
}
/**
 * 4.5. Нажатие кнопки Авто в наличии
 */
export function gtmCarAvailable() {
  return ym(29134875, "reachGoal", "car_in_stock_cars_showroom");
}
/**
 * 4.6. Нажатие кнопки Оставить заявку
 */
export function gtmSubmitApplication() {
  return ym(29134875, "reachGoal", "click_request_cars_showroom");
}
/**
 * 4.7. Нажатие кнопки «Отправить запрос» поп-апе с формой
 */
export function gtmSubmitApplicationSend() {
  // return ym(88657503, "reachGoal", "submit_application_send");
}
/**
 * 4.7. Успешная отправка заявки
 */
export function gtmSubmitApplicationPush() {
  dataLayer.push({
    event: "send_form_request_cars_showroom",
  });

  return ym(29134875, "reachGoal", "send_form_request_cars_showroom");
}
/**
 * 5.1. Нажатие кнопки vesta-sw-cross в шоуруме
 */
export function gtmVestaSWCross() {
  return ym(29134875, "reachGoal", "click_vestaswcross_cars_showroom");
}
/**
 * 5.1. Нажатие Экстерьер в разделе Vesta SW Cross
 */
export function gtmVestaSWCrossExterior() {
  return ym(29134875, "reachGoal", "vesta_sw_cross_exterior_cars_showroom");
}
/**
 * 5.2. Нажатие Интерьер в разделе Vesta SW Cross
 */
export function gtmVestaSWCrossInterior() {
  return ym(29134875, "reachGoal", "vesta_sw_cross_interior_cars_showroom");
}
/**
 * 5.3. Нажатие Цвет в разделе Vesta SW Cross
 */
export function gtmVestaSWCrossColour() {
  // return ym(88657503, "reachGoal", "vesta-sw-cross_colour");
}
/**
 * 5.8.10.1. Нажатие “Здесь” в пине “Мультимедиа”
 */
export function gtmVestaSWMultimediaHere() {
  // return ym(
  //   88657503,
  //   "reachGoal",
  //   "vesta-sw-cross_interior_pin-multimedia_here"
  // );
}
/**
 * 6.1. Нажатие кнопки vesta sedan в шоуруме
 */
export function gtmVestaSedan() {
  // return ym(88657503, "reachGoal", "vesta-sedan");
}
/**
 * 6.1. Нажатие Экстерьер в разделе vesta sedan
 */
export function gtmVestaSedanExterior() {
  // return ym(88657503, "reachGoal", "vesta-sedan_exterior");
}
/**
 * 6.2. Нажатие Интерьер в разделе vesta sedan
 */
export function gtmVestaSedanInterior() {
  // return ym(88657503, "reachGoal", "vesta-sedan_interior");
}
/**
 * 6.3. Нажатие Цвет в разделе vesta sedan
 */
export function gtmVestaSedanColour() {
  // return ym(88657503, "reachGoal", "vesta-sedan_colour");
}

/**
 * 6.8.10.1. Нажатие “Здесь” в пине “Мультимедиа”
 */
export function gtmVestaSedanMultimediaHere() {
  // return ym(88657503, "reachGoal", "vesta-sedan_interior_pin-multimedia_here");
}

/**
 * Нажатие на поле "модель" в заявке
 */
export function gtmApplicationModel() {
  return ym(29134875, "reachGoal", "start_form_interection_cars_showroom");
}

/**
 * Нажатие на любое поле в заявке
 */
export function gtmApplicationFields(field) {
  const formFieldCarsShowroom = { cars_showroom_field: field };
  return ym(
    29134875,
    "reachGoal",
    "form_interection_cars_showroom",
    formFieldCarsShowroom
  );
}

/**
 * Нажатие на чекбокс в заявке
 */
export function gtmApplicationCheckbox() {
  return ym(29134875, "reachGoal", "personal_data_agreement", {
    "URL goalID 260944692": document.location.href,
  });
}

/**
 * Нажатие кнопки aura в шоуруме
 */
export function gtmAura() {
  return ym(29134875, "reachGoal", "click_aura_cars_showroom");
}
/**
 * Нажатие Экстерьер в разделе Aura
 */
export function gtmAuraExterior() {
  return ym(29134875, "reachGoal", "aura_exterior_cars_showroom");
}
/**
 * Нажатие Интерьер в разделе Aura
 */
export function gtmAuraInterior() {
  return ym(29134875, "reachGoal", "aura_interior_cars_showroom");
}
/**
 * Нажатие на Цвет
 */
export function gtmColorClick(color) {
  return ym(29134875, "reachGoal", "click_color_cars_showroom", {
    color: color,
  });
}
