<template>
  <section>
    <img
      :src="require(`@/assets/img/enjoy/music${step == 2 ? '-2' : ''}.webp`)"
      alt=""
    />
    <highlight-button
      v-show="step === 1"
      @click-button="onAudio"
      style="top: 51.5%; left: 16.4%; width: 15.1%; height: 10.6%"
    />
    <highlight-button
      v-show="step === 2"
      @click-button="offAudio"
      style="top: 78.9%; left: 42.4%; width: 15.1%; height: 10.6%"
    />
    <audio ref="audio" class="audio">
      <source src="@/assets/img/enjoy/LADA_audio.mp3" type="audio/wav" />
    </audio>
  </section>
</template>

<script>
import HighlightButton from "../HighlightButton.vue";
export default {
  components: { HighlightButton },
  name: "EnjoyMusic",
  data() {
    return {
      step: 1,
      audio: null,
    };
  },
  mounted() {
    this.audio = this.$refs.audio;
  },
  methods: {
    onAudio() {
      this.step = 2;
      this.audio.play();
    },
    offAudio() {
      this.step = 1;
      this.audio.pause();
    },
  },
};
</script>

<style lang="scss" scoped>
section {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  > img,
  > video {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: 50% 50%;
  }
  .audio {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
  }
}
</style>
