<template>
  <footer class="footer">
    <div class="footer__item">
      <slot name="content"></slot>
    </div>
    <div class="footer__item">
      <div class="btn-group">
        <ButtonFeedback mode="desktop" />
        <ButtonCarInStock v-show="$route.path !== '/aura'" mode="desktop" />
      </div>
    </div>
    <div class="footer__item">
      <slot name="controls"></slot>
    </div>
    <div class="footer__item">
      <div class="btn-group">
        <slot name="btn-right"></slot>
        <ButtonConfigurator />
      </div>
    </div>
  </footer>
  <footer class="footer-mobile">
    <div v-if="!showMore" class="footer__item controls">
      <slot name="controls"></slot>
    </div>
    <div class="footer__item">
      <div v-if="!showMore" class="footer__item">
        <slot name="content"></slot>
      </div>
    </div>
    <div v-if="showMore" class="footer__item">
      <ButtonCarInStock v-show="$route.path !== '/aura'" mode="mobile" />
      <ButtonConfigurator />
    </div>
    <div class="footer__item">
      <div class="btn-multi btn-group btn-primary">
        <ButtonFeedback mode="mobile" />
        <span class="separate"></span>
        <button
          class="btn-more"
          @click="showMore = !showMore"
          :class="{ active: showMore }"
        >
          Еще
        </button>
      </div>
    </div>
  </footer>
</template>

<script>
import ButtonConfigurator from "@/components/ButtonConfigurator.vue";
import ButtonCarInStock from "@/components/ButtonCarInStock.vue";
import ButtonFeedback from "@/components/ButtonFeedback.vue";

export default {
  name: "FooterComponent",
  components: {
    ButtonConfigurator,
    ButtonCarInStock,
    ButtonFeedback,
  },
  data() {
    return {
      showMore: false,
    };
  },
};
</script>

<style lang="scss">
.footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto auto;
  pointer-events: none;
  align-items: end;
  z-index: 100;
  padding: uniW(24);
  button,
  a {
    pointer-events: all;
  }
  @include mobile {
    grid-template-rows: auto auto auto;
    grid-template-columns: 1fr auto;
    align-items: center;
    gap: uniWM(16);
    padding: uniWM(16);
    display: none;
  }
  &__item {
    &:nth-child(1) {
      grid-column: span 3;
      @include mobile {
        grid-column: auto;
        // grid-column: span 1;
        // order: 2;
      }
    }
    &:nth-child(2) {
      @include mobile {
        // order: 4;
        // grid-column: span 2;
      }
    }
    &:nth-child(3) {
      text-align: center;
      * {
        pointer-events: all;
      }
      @include mobile {
        // order: 1;
        // grid-column: span 2;
      }
    }
    &:nth-child(4) {
      text-align: right;
      @include mobile {
        // grid-column: span 1;
        // order: 3;
      }
    }
  }
  &__title {
    font-weight: 700;
    font-size: uniW(24);
    @include mobile {
      display: none;
    }
  }
  &__name {
    font-family: var(--fontSecond);
    font-size: uniW(48);
    text-transform: uppercase;
    margin-bottom: uniW(10);
    @include mobile {
      display: grid;
      align-items: center;
      font-size: uniWM(32);
      margin-bottom: 0;
    }
  }
  &-mobile {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 100;
    grid-template-columns: 1fr;
    align-items: center;
    gap: uniWM(16);
    padding: uniWM(16);
    display: none;
    @include mobile {
      display: grid;
      grid-template-columns: 1fr;
    }
    .btn-multi button {
      @include reset-button;
      display: flex;
      align-items: center;
      text-transform: uppercase;
    }
    .btn-primary {
      text-align: left;
      justify-content: flex-start;
      padding-left: uniWM(20);
      &:hover {
        background: var(--colorOrange);
      }
    }
    .btn-multi {
    }
    .separate {
      height: uniWM(32);
      width: uniWM(1);
      background: var(--colorWhite);
      margin: 0 uniWM(20);
    }
    .btn-group {
      z-index: 101;
    }
    .btn-group-abs {
      position: absolute;
      display: grid;
      width: 100%;
      z-index: 100;
      grid-template-columns: 1fr;
      gap: uniWM(16);
      padding: uniWM(16) uniWM(16) uniWM(126) uniWM(16);
    }
  }
}
</style>
