<template>
  <section v-if="step === 1">
    <img src="@/assets/img/enjoy/contacts.webp" alt="" />
    <highlight-button
      @click-button="step = 2"
      style="top: 4%; left: 89.7%; width: 7.4%; height: 5.5%"
    />
  </section>
  <section v-else>
    <img src="@/assets/img/enjoy/phone.webp" alt="" />
    <highlight-button
      @click-button="$emit('change', 'settings')"
      style="top: 92%; left: 60.7%; width: 9.1%; height: 7%"
    />
    <input
      v-maska="maskPhone"
      v-model="phone"
      autocomplete="off"
      class="form-control"
      type="tel"
      ref="inpt"
    />
    <highlight-button
      @click-button="input('1')"
      class="circle"
      style="left: 23%; top: 21.7%; width: 12.5%; height: 9.3%"
    />
    <highlight-button
      @click-button="input('2')"
      class="circle"
      style="left: 41.4%; top: 21.7%; width: 12.5%; height: 9.3%"
    />
    <highlight-button
      @click-button="input('3')"
      class="circle"
      style="left: 59.7%; top: 21.7%; width: 12.5%; height: 9.3%"
    />
    <highlight-button
      @click-button="input('4')"
      class="circle"
      style="left: 23%; top: 36%; width: 12.5%; height: 9.3%"
    />
    <highlight-button
      @click-button="input('5')"
      class="circle"
      style="left: 41.4%; top: 36%; width: 12.5%; height: 9.3%"
    />
    <highlight-button
      @click-button="input('6')"
      class="circle"
      style="left: 59.7%; top: 36%; width: 12.5%; height: 9.3%"
    />
    <highlight-button
      @click-button="input('7')"
      class="circle"
      style="left: 23%; top: 50.6%; width: 12.5%; height: 9.3%"
    />
    <highlight-button
      @click-button="input('8')"
      class="circle"
      style="left: 41.4%; top: 50.6%; width: 12.5%; height: 9.3%"
    />
    <highlight-button
      @click-button="input('9')"
      class="circle"
      style="left: 59.7%; top: 50.6%; width: 12.5%; height: 9.3%"
    />
    <highlight-button
      @click-button="input('+')"
      class="circle"
      style="left: 23%; top: 65%; width: 12.5%; height: 9.3%"
    />
    <highlight-button
      @click-button="input('0')"
      class="circle"
      style="left: 41.4%; top: 65%; width: 12.5%; height: 9.3%"
    />
    <highlight-button
      @click-button="clear"
      class="circle"
      style="left: 59.7%; top: 65%; width: 12.5%; height: 9.3%"
    />
  </section>
</template>

<script>
import HighlightButton from "../HighlightButton.vue";

export default {
  components: { HighlightButton },
  name: "EnjoyPhone",
  data() {
    return {
      step: 1,
      phone: "",
      maskPhone: `+#-### ###-##-##`,
    };
  },
  methods: {
    input(val) {
      this.phone += val;
      this.$nextTick(() => this.$refs.inpt.dispatchEvent(new Event("input")));
    },
    clear() {
      this.phone = this.phone.slice(0, this.phone.length - 1);
    },
  },
};
</script>

<style lang="scss" scoped>
section {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  > img,
  > video {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: 50% 50%;
  }
  input {
    position: absolute;
    left: 50%;
    width: 100%;
    top: 12%;
    transform: translateX(-50%);
    text-align: center;
    background: none;
    border: none;
    color: white;
    font-size: 24px;
    font-family: "LADA Pragmatica";
    font-weight: 700;
    height: 40px;
    &:focus {
      outline: none;
    }
    @include mobile {
      top: 9%;
      font-size: 14px;
    }
  }
  .btn-num {
    @include reset-button;
    position: absolute;
    width: 10%;
    height: 8%;

    // background: white;
    // opacity: 0.3;
  }
}
</style>
