import {
  PAGE_NAME_AURA,
  PAGE_NAME_E_LARGUS,
  PAGE_NAME_VESTA_SW,
} from "@/data/constants";

export const PANORAMA_LIST = [
  {
    src: require("@/assets/img/hall/light-q85.webp"),
    srcMobile: require("@/assets/img/hall/mobile/light-q25.webp"),
    startLookAt: 270,
  },
  {
    src: require("@/assets/img/hall/light2-q85.webp"),
    srcMobile: require("@/assets/img/hall/mobile/light2-q25.webp"),
    startLookAt: 270,
  },
];

export const INFOSPOT_LIST1 = [
  {
    // e-Largus
    position: {
      x: 4250,
      y: -900,
      z: 2800,
    },
    size: 320,
    sizeMobile: 450,
    className: "popover_id_main_left1",
    nextPage: PAGE_NAME_E_LARGUS,
    areaHover: {
      position: {
        x: 4250,
        y: -900,
        z: 2800,
      },
      size: {
        width: 2700,
        height: 1300,
      },
      rotate: {
        y: 140,
      },
    },
  },
];

export const INFOSPOT_LIST2 = [
  {
    // кинотеатр
    position: {
      x: 5000,
      y: -1000,
      z: 0,
    },
    size: 320,
    sizeMobile: 450,
    className: "popover_id_main_center",
    nextPage: PAGE_NAME_AURA,
    areaHover: {
      position: {
        x: 5000,
        y: -1000,
        z: 0,
      },
      size: {
        width: 2700,
        height: 1300,
      },
      rotate: {
        y: 140,
      },
    },
  },
  {
    // vesta sw cross
    position: {
      x: 4250,
      y: -900,
      z: -2800,
    },
    size: 320,
    sizeMobile: 450,
    className: "popover_id_main_right1",
    nextPage: PAGE_NAME_VESTA_SW,
    areaHover: {
      position: {
        x: 4250,
        y: -900,
        z: -2800,
      },
      size: {
        width: 2700,
        height: 1300,
      },
      rotate: {
        y: 50,
      },
    },
  },
];
