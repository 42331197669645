export const PANORAMA = {
  options: {
    name: "interior-e-largus",
    items: [
      {
        src: require("@/assets/img/cars/e-largus/interior/light-q75.webp"),
        srcMobile: require("@/assets/img/cars/e-largus/interior/mobile/light-q25.webp"),
        startLookAt: 270,
      },
      {
        src: require("@/assets/img/cars/e-largus/interior/dark-q75.webp"),
        srcMobile: require("@/assets/img/cars/e-largus/interior/mobile/dark-q25.webp"),
        startLookAt: 270,
      },
    ],
  },
  infospots: [
    {
      position: {
        x: 5300,
        y: -2000,
        z: 1300,
      },
      title: "Кожаная отделка руля с синей прострочкой",
      desc: "Идеально для работы и повседневных поездок.",
      media: { picture: require("@/assets/img/cars/e-largus/media/6.webp") },
      gtmReachGoal: "e-largus-cross_interior_pin-doors",
    },
    {
      position: {
        x: 2700,
        y: -3000,
        z: -2900,
      },
      title: "Оригинальная отделка сидений",
      desc: "Цветные элементы сочетаются с деталями отделки экстерьера и интерьера.",
      media: { picture: require("@/assets/img/cars/e-largus/media/7.webp") },
      gtmReachGoal: "e-largus-cross_interior_pin-doors",
    },
    {
      position: {
        x: 2700,
        y: -3200,
        z: 0,
      },
      title: "Переключатель режимов движения",
      desc: "Удобное и современное решение в виде поворотной шайбы.",
      media: { picture: require("@/assets/img/cars/e-largus/media/8.webp") },
      gtmReachGoal: "e-largus-cross_interior_pin-doors",
    },
    {
      position: {
        x: 4800,
        y: -1800,
        z: 3400,
      },
      title: "Мультифункциональное рулевое колесо",
      desc: "Позволяет управлять электроникой, не отвлекаясь от вождения.",
      media: { picture: require("@/assets/img/cars/e-largus/media/9.webp") },
      gtmReachGoal: "e-largus-cross_interior_pin-doors",
    },
    {
      position: {
        x: 4300,
        y: -3000,
        z: 0,
      },
      title: "Кондиционер",
      desc: "Базовый элемент комфорта, актуальный и летом, и в межсезонье.",
      media: { picture: require("@/assets/img/cars/e-largus/media/10.webp") },
      gtmReachGoal: "e-largus-cross_interior_pin-doors",
    },
    {
      position: {
        x: 3800,
        y: -2500,
        z: 3500,
      },
      title: "Передние и задние электростеклоподъёмники",
      desc: "Удобно и практично.",
      media: { picture: require("@/assets/img/cars/e-largus/media/11.webp") },
      gtmReachGoal: "e-largus-cross_interior_pin-doors",
    },
    {
      position: {
        x: 4300,
        y: -1800,
        z: -500,
      },
      title: "Мультимедиа EnjoY Pro",
      desc: "С онлайн-сервисами Яндекса, навигацией и картами электрозаправок.",
      media: { picture: require("@/assets/img/cars/e-largus/media/12.webp") },
      gtmReachGoal: "e-largus-cross_interior_pin-doors",
    },
    {
      position: {
        x: 4300,
        y: -1000,
        z: 1600,
      },
      title: "7-дюймовая цифровая комбинация приборов",
      desc: "С указанием уровня заряда и запаса хода.",
      media: { picture: require("@/assets/img/cars/e-largus/media/13.webp") },
      gtmReachGoal: "e-largus-cross_interior_pin-doors",
    },
    {
      position: {
        x: 2700,
        y: -3000,
        z: 1900,
      },
      title: "Подогрев сидений",
      desc: "Создает комфортный микроклимат с первых секунд.",
      media: { picture: require("@/assets/img/cars/e-largus/media/14.webp") },
      gtmReachGoal: "e-largus-cross_interior_pin-doors",
    },
    {
      position: {
        x: 3700,
        y: -600,
        z: 4200,
      },
      title: "Электрорегулировка передних зеркал с обогревом",
      desc: "Повышает обзорность и безопасность городских маневров.",
      media: { picture: require("@/assets/img/cars/e-largus/media/15.webp") },
      gtmReachGoal: "e-largus-cross_interior_pin-doors",
    },
    {
      position: {
        x: 4300,
        y: -2600,
        z: 1900,
      },
      title: "Подогрев руля",
      desc: "Повышает комфорт вождения в холодное время года.",
      media: { picture: require("@/assets/img/cars/e-largus/media/16.webp") },
      gtmReachGoal: "e-largus-cross_interior_pin-doors",
    },
    {
      position: {
        x: 4300,
        y: -300,
        z: -500,
      },
      title: "Обогрев ветрового стекла",
      desc: "Предотвращает запотевание и обеспечивает быструю разморозку.",
      media: { picture: require("@/assets/img/cars/e-largus/media/17.webp") },
      gtmReachGoal: "e-largus-cross_interior_pin-doors",
    },
    {
      position: {
        x: 4300,
        y: 1300,
        z: 200,
      },
      title: "Датчик дождя с регулировкой чувствительности",
      desc: "Быстро реагирует на изменение условий.",
      media: { picture: require("@/assets/img/cars/e-largus/media/18.webp") },
      gtmReachGoal: "e-largus-cross_interior_pin-doors",
    },
    {
      position: {
        x: 2700,
        y: -5200,
        z: 0,
      },
      title: "Подлокотник с откидывающейся крышкой",
      desc: "Для мелочей и важных вещей.",
      media: { picture: require("@/assets/img/cars/e-largus/media/19.webp") },
      gtmReachGoal: "e-largus-cross_interior_pin-doors",
    },
    {
      position: {
        x: 4300,
        y: -3400,
        z: -400,
      },
      title: "2 USB-розетки",
      desc: "На панели приборов (для подзарядки гаджетов) и внутри вещевого ящика (для подключения модема).",
      media: { picture: require("@/assets/img/cars/e-largus/media/20.webp") },
      gtmReachGoal: "e-largus-cross_interior_pin-doors",
    },
    {
      position: {
        x: 4300,
        y: -880,
        z: 300,
      },
      title: "Отопитель салона",
      desc: "Cпециальная система термоменеджмента с электрическим нагревателем охлаждающей жидкости.",
      media: { picture: require("@/assets/img/cars/e-largus/media/21.webp") },
      gtmReachGoal: "e-largus-cross_interior_pin-doors",
    },
  ],
};

export const GALLERY = {
  folder: "e-largus/exterior",
  colorActive: 0,
  colors: [
    {
      title: "БЕЛОЕ ОБЛАКО",
      hex: "#FFFFFF",
      folder: "white",
      fileName: {
        light: "ELARGUSwhite_day00",
      },
    },
    {
      title: "СИНИЙ",
      hex: "#1b1e46",
      folder: "blue",
      fileName: {
        light: "ELARGUSblue_day00",
      },
    },
    {
      title: "СЕРЫЙ",
      hex: "#C3C4C4",
      folder: "grey",
      fileName: {
        light: "ELARGUSgrey_day00",
      },
    },
    {
      title: "ПЛАТИНА",
      hex: "#C3C4C4",
      folder: "platinum",
      fileName: {
        light: "ELARGUSplatinum_day00",
      },
    },
    {
      title: "БОРНЕО",
      hex: "#C3C4C4",
      folder: "borneo",
      fileName: {
        light: "ELARGUSborneo_day00",
      },
    },
  ],
  breakpoints: [
    {
      slideIndex: 20, // 10
      items: [
        {
          offset: {
            top: "3vw",
            left: "-2vw",
          },
          offsetMobile: {
            top: "6.5",
            left: "-3",
          },
          title: "15-дюймовые литые диски",
          desc: "Оригинальный дизайн колёс выделяет автомобиль.",
          media: {
            picture: require("@/assets/img/cars/e-largus/media/1.webp"),
          },
          gtmReachGoal: "e-largus-cross-exterior-pin-frontbumper",
        },
        {
          offset: {
            top: "5vw",
            left: "4vw",
          },
          offsetMobile: {
            top: "8",
            left: "3",
          },
          title: "Противотуманные фары",
          desc: "Повышают безопасность движения в сложных условиях.",
          media: {
            picture: require("@/assets/img/cars/e-largus/media/2.webp"),
          },
          gtmReachGoal: "e-largus-cross-exterior-pin-frontbumper",
        },
        {
          offset: {
            top: "-1vw",
            left: "16vw",
          },
          offsetMobile: {
            top: "3",
            left: "16",
          },
          title: "Шильд с названием модели",
          desc: "Один из элементов, отличающих электромобиль.",
          media: {
            picture: require("@/assets/img/cars/e-largus/media/3.webp"),
          },
          gtmReachGoal: "e-largus-cross-exterior-pin-frontbumper",
        },
        {
          offset: {
            top: "-4.5vw",
            left: "-7vw",
          },
          offsetMobile: {
            top: "-1.5",
            left: "-8",
          },
          title: "Боковой повторитель поворота на зеркале",
          desc: "Улучшает заметность автомобиля в потоке.",
          media: {
            picture: require("@/assets/img/cars/e-largus/media/4.webp"),
          },
          gtmReachGoal: "e-largus-cross-exterior-pin-frontbumper",
        },
        {
          offset: {
            top: "-9.5vw",
            left: "-6.5vw",
          },
          offsetMobile: {
            top: "-7",
            left: "-8",
          },
          title: "Рейлинги крыши",
          desc: "Практичность и стильная конструкция.",
          media: {
            picture: require("@/assets/img/cars/e-largus/media/5.webp"),
          },
          gtmReachGoal: "e-largus-cross-exterior-pin-frontbumper",
        },
      ],
    },
    {
      slideIndex: 80, // 70
      items: [],
    },
    {
      slideIndex: 200, // 190
      items: [],
    },
  ],
};
