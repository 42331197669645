<template>
  <div
    :data-id="infospot.className"
    class="popover-core"
    :class="infospot.className"
  >
    <div
      class="popover-point popover-point_type_plus popover-point_size_interior"
    ></div>
    <Popover
      class="popover_type_overflow"
      :media="infospot.media"
      :title="infospot.title"
      :desc="infospot.desc"
    />
  </div>
</template>

<script>
import Popover from "@/components/Popover.vue";

export default {
  name: "PanoramaInteriorInfospot",
  components: {
    Popover,
  },
  props: {
    infospot: Object,
  },
};
</script>
