<template>
  <transition name="fade">
    <div v-if="videoModalShow" class="modal-video" @click="hideVideoModal">
      {{ videoModalMedia }}
      <video autoplay playsinline :src="videoModalMedia.video"></video>
    </div>
  </transition>
</template>

<script>
import { mapState, mapMutations } from "vuex";

export default {
  name: "VideoModalComponent",
  computed: {
    ...mapState(["videoModalShow", "videoModalMedia"]),
  },
  mounted() {
    document.addEventListener("keyup", this.onClose);
  },
  unmounted() {
    document.removeEventListener("keyup", this.onClose);
  },
  methods: {
    ...mapMutations(["hideVideoModal"]),
    onClose(event) {
      if (event.keyCode === 27) {
        this.hideVideoModal();
      }
    },
  },
};
</script>

<style lang="scss">
.modal-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 1);
  z-index: 5500;
  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
    background-color: var(--colorBlack);
  }
}

// .fade-enter-active,
// .fade-leave-active {
//   transition: all 0.1s var(--cubicBezier);
// }
.fade-leave-active {
  transition: all 0.2s var(--cubicBezier);
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
