<template>
  <div
    v-if="show"
    class="swiper-content-cinema"
    :class="{
      'swiper-content-cinema_controls_disabled': videos.length <= 3,
      'swiper-content-cinema_type_single': videos.length === 1,
      'swiper-content-cinema_type_double': videos.length === 2,
    }"
  >
    <swiper
      :modules="[Controller]"
      :slides-per-view="slidesPerView"
      @swiper="setControlledSwiper"
      @slideChangeTransitionEnd="onSlideChange"
    >
      <!-- выравниваем по центру, если одно видео на десктопе -->
      <swiper-slide v-if="videos.length === 1 && !isPortrait"></swiper-slide>
      <swiper-slide v-for="(item, index) in videos" :key="index">
        <div class="video-thumb">
          <div class="video-thumb__title" v-html="item.title"></div>
          <button
            class="video-thumb__button"
            :class="{
              playing: playing && videoActive === index,
              'video-play-button': !playing,
              active: !isMobile && videoActive === index,
            }"
            @click="play(index)"
          >
            <div class="video-thumb__pic">
              <img :src="item.preview" alt="" />
            </div>
          </button>
        </div>
      </swiper-slide>
    </swiper>
  </div>
  <div v-if="show && videos.length > 3" class="swiper-control-cinema">
    <swiper
      :modules="[Controller, Navigation, Pagination]"
      :controller="{ control: controlledSwiper }"
      :slides-per-view="slidesPerView"
      navigation
      :pagination="{ clickable: true }"
      @swiper="setMainSwiper"
    >
      <swiper-slide v-for="(item, index) in videos" :key="index"></swiper-slide>
    </swiper>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { Navigation, Pagination, Controller } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import { ref } from "vue";

export default {
  name: "VideoSwiperComponent",
  props: {
    videos: Array,
    play: Function,
    playing: Boolean,
    videoActive: Number,
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  watch: {
    isPortrait() {
      this.show = false;
      this.$nextTick(() => (this.show = true));
    },
  },
  data() {
    return {
      show: true,
    };
  },
  computed: {
    ...mapState(["isMobile", "isPortrait"]),
    slidesPerView() {
      return this.isPortrait ? "auto" : 3;
    },
  },
  setup() {
    const mainSwiper = ref(null);
    const controlledSwiper = ref(null);
    const setMainSwiper = (swiper) => {
      mainSwiper.value = swiper;
    };
    const setControlledSwiper = (swiper) => {
      controlledSwiper.value = swiper;
    };
    const onSlideChange = (swiper) => {
      const el = document.getElementById(`video_${swiper.realIndex}`);

      if (el) {
        el.currentTime = 0;
        el.play();
      }

      if (mainSwiper.value.realIndex !== swiper.realIndex) {
        mainSwiper.value.slideTo(swiper.realIndex);
      }
    };
    return {
      Navigation,
      Pagination,
      Controller,
      controlledSwiper,
      setMainSwiper,
      setControlledSwiper,
      onSlideChange,
    };
  },
};
</script>

<style lang="scss">
.video-thumb {
  margin: uniW(1) uniW(8);
  margin-top: uniW(100);
  position: relative;
  @include mobile {
    margin: 0 auto;
    width: fit-content;
  }
  &:hover {
    .video-thumb__title {
      transform: scale(1);
      opacity: 1;
    }
  }
  &__title {
    position: absolute;
    bottom: 100%;
    left: uniW(-8);
    width: calc(100% + uniW(16));
    margin-bottom: uniW(8);
    font-size: uniW(13);
    line-height: uniW(16);
    text-align: center;
    text-transform: uppercase;
    opacity: 0;
    transform: scale(0.5) translateY(100%);
    transition: var(--transitionTime) var(--cubicBezier);
    pointer-events: none;
    @include mobile {
      display: none;
    }
  }
  &__button {
    @include reset-button;
    border: uniW(1) solid var(--colorBlack);
    width: uniW(120);
    border-radius: uniW(4);
    transition: var(--transitionTime) var(--cubicBezier);
    @include mobile {
      border-width: uniWM(1);
      width: uniWM(120);
      border-radius: uniWM(4);
    }
    &::before {
      position: absolute;
      top: 50%;
      left: 50%;
      width: uniW(56);
      height: uniW(56);
      transform: translate3d(-50%, -47%, 0);
      background-image: url("data:image/svg+xml,%3Csvg width='56' height='56' viewBox='0 0 56 56' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg filter='url(%23a)'%3E%3Crect x='8' y='4' width='40' height='40' rx='20' fill='%23fff'/%3E%3C/g%3E%3Cpath d='M24.3389 17.6523v13.0134M35.7255 24.1582l-11.3866 6.5067' stroke='%23000' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M24.3389 30.2586v-12.2l10.37 6.1-10.37 6.1Z' fill='%23000'/%3E%3Cpath d='m35.7255 24.159-11.3866-6.5067' stroke='%23000' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cdefs%3E%3Cfilter id='a' x='0' y='0' width='56' height='56' filterUnits='userSpaceOnUse' color-interpolation-filters='sRGB'%3E%3CfeFlood flood-opacity='0' result='BackgroundImageFix'/%3E%3CfeColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha'/%3E%3CfeOffset dy='4'/%3E%3CfeGaussianBlur stdDeviation='4'/%3E%3CfeComposite in2='hardAlpha' operator='out'/%3E%3CfeColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0'/%3E%3CfeBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_17_3350'/%3E%3CfeBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_17_3350' result='shape'/%3E%3C/filter%3E%3C/defs%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-size: contain;
      content: "";
      @include mobile {
        width: uniWM(56);
        height: uniWM(56);
      }
    }
    &:hover:not(.active) {
      border-color: var(--colorGrey);
    }
    &.active {
      border-color: var(--colorOrange);
      box-shadow: 0 0 0 uniW(1) var(--colorOrange);
      @include mobile {
        box-shadow: 0 0 0 uniWM(1) var(--colorOrange);
      }
    }
    &.playing {
      &::before {
        background-image: url("data:image/svg+xml,%3Csvg width='56' height='56' viewBox='0 0 56 56' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg filter='url(%23a)'%3E%3Crect x='8' y='4' width='40' height='40' rx='20' fill='%23fff'/%3E%3C/g%3E%3Cpath d='M32.1663 18.167v11.6667M23.833 18.167v11.6667V18.167Z' stroke='%23000' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cdefs%3E%3Cfilter id='a' x='0' y='0' width='56' height='56' filterUnits='userSpaceOnUse' color-interpolation-filters='sRGB'%3E%3CfeFlood flood-opacity='0' result='BackgroundImageFix'/%3E%3CfeColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha'/%3E%3CfeOffset dy='4'/%3E%3CfeGaussianBlur stdDeviation='4'/%3E%3CfeComposite in2='hardAlpha' operator='out'/%3E%3CfeColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0'/%3E%3CfeBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_17_3344'/%3E%3CfeBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_17_3344' result='shape'/%3E%3C/filter%3E%3C/defs%3E%3C/svg%3E");
      }
    }
  }
  &__pic {
    border-radius: uniW(4);
    overflow: hidden;
    @include mobile {
      border-radius: uniWM(4);
    }
    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }
}
</style>
