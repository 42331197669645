<template>
  <button v-if="mode === 'mobile'" class="menu-button-info" @click="onClick">
    НАВИГАЦИЯ ПО ШОУРУМУ
  </button>
  <button v-else class="button-info" @click="onClick">
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M12 22c5.5228 0 10-4.4772 10-10 0-5.5229-4.4772-10-10-10C6.4771 2 2 6.4771 2 12c0 5.5228 4.4771 10 10 10ZM12 7h.01"
        stroke="#4F5862"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10 11h2v5M10 16h4"
        stroke="#4F5862"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </button>
</template>

<script>
import { mapMutations } from "vuex";
import { gtmNavigationInfo } from "@/utils/gtm";

export default {
  name: "ButtonTutorial",
  props: {
    mode: String,
  },
  methods: {
    ...mapMutations(["showTutorialModal"]),
    onClick() {
      this.showTutorialModal();
      this.$emit("show");
      gtmNavigationInfo();
    },
  },
};
</script>

<style lang="scss" scoped>
.button-info {
  @include reset-button;
  width: uniW(40);
  height: uniW(40);
  background: var(--colorWhite);
  border-radius: uniW(40);
  transition: var(--transitionTime) var(--cubicBezier);
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    width: uniW(24);
    height: uniW(24);
    path {
      transition: var(--transitionTime) var(--cubicBezier);
    }
  }
}
</style>
