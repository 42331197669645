<template>
  <button
    class="btn-switcher"
    :class="{
      active: isInterior,
      'button-to-exterior': isInterior,
      'button-to-interior': !isInterior,
    }"
    @click="onClickChangeMode"
  >
    <span>Экстерьер</span>
    <span>Интерьер</span>
  </button>
</template>

<script>
export default {
  name: "ButtonSwitcher",
  computed: {
    isInterior() {
      const { mode } = this.$route.query;
      return mode === "interior";
    },
  },
  methods: {
    onClickChangeMode() {
      this.$router.push({
        query: { mode: this.isInterior ? "exterior" : "interior" },
      });
    },
  },
};
</script>
