export const TUTORIAL_LIST = [
  {
    src: require("@/assets/video/LADA_Motion.mp4"),
    srcMobile: require("@/assets/video/LADA_Motion_mobile.mp4"),
    title: "Навигация по шоуруму",
    desc: "Используйте курсор или стрелки клавиатуры для перемещения в&nbsp;режиме 360&deg;",
  },
  {
    src: require("@/assets/video/LADA_Day_Night.mp4"),
    srcMobile: require("@/assets/video/LADA_Day_Night_mobile.mp4"),
    title: "День и ночь",
    desc: "Переключайтесь между режимами день и&nbsp;ночь для более глубокого погружения в&nbsp;атмосферу интерьера",
  },
  {
    src: require("@/assets/video/LADA_Car_Show.mp4"),
    srcMobile: require("@/assets/video/LADA_Car_Show_mobile.mp4"),
    title: "Осмотр автомобиля",
    desc: "Меняйте ракурс обзора при просмотре автомобиля с&nbsp;помощью курсора или стрелок расположенных у&nbsp;основания платформы",
  },
  {
    src: require("@/assets/video/LADA_IntExt.mp4"),
    srcMobile: require("@/assets/video/LADA_IntExt_mobile.mp4"),
    title: "Интерьер и Экстерьер",
    desc: "Переключайтесь между режимами интерьер и&nbsp;экстерьер для детального просмотра всех преимуществ автомобиля",
  },
];
