<template>
  <router-link class="btn-back" to="/">В шоурум</router-link>
</template>

<script>
export default {
  name: "ButtonBack",
};
</script>

<style lang="scss" scoped>
.btn {
  &-back {
    @include reset-button;
    font-weight: 700;
    font-size: uniW(14);
    text-transform: uppercase;
    color: var(--colorWhite);
    text-shadow: 0px uniW(1) uniW(4) rgba(0, 0, 0, 0.25);
    padding-left: uniW(32);
    position: relative;
    pointer-events: all;
    @include mobile {
      text-indent: -9999px;
      width: uniWM(40);
      height: uniWM(40);
      position: absolute;
      top: calc(uniWM(16) + 56px); // компенсация для хедера
      left: uniWM(8);
      padding: 0;
      z-index: 1000;
    }
    &:hover {
      &::before {
        transform: translateX(uniW(-2)) translateY(-50%);
        @include mobile {
          transform: translate3d(-50%, -50%, 0);
        }
      }
    }
    &:before {
      position: absolute;
      top: 50%;
      left: 0;
      width: uniW(24);
      height: uniW(24);
      background-size: contain;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-image: url("data:image/svg+xml,%3Csvg width='24' height='25' viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m8 5-5 5 5 5' stroke='%23fff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M3 10h8c5.523 0 10 4.477 10 10v1' stroke='%23fff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
      content: "";
      transform: translateY(-50%);
      transition: var(--transitionTime) var(--cubicBezier);
      @include mobile {
        width: uniWM(24);
        height: uniWM(24);
        left: 50%;
        transform: translate3d(-50%, -50%, 0);
      }
    }
  }
}
</style>
