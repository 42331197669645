<template>
  <nav class="nav">
    <ul>
      <li v-for="(item, index) in links" :key="index">
        <router-link
          class="button-vesta-sw-cross"
          :to="item.to"
          @click="onClick(item.to)"
        >
          <img :src="item.src" alt="" />
          {{ item.name }}
        </router-link>
      </li>
    </ul>
  </nav>
</template>

<script>
import { PAGE_NAME_AURA, PAGE_NAME_VESTA_SW } from "@/data/constants";
import { gtmVestaSWCross, gtmVestaSedan } from "@/utils/gtm";

export default {
  data() {
    return {
      links: [
        {
          name: "AURA",
          to: { name: PAGE_NAME_AURA },
          src: require("@/assets/img/cars/aura/preview/mobile/preview-aura.webp"),
        },
        {
          name: "VESTA SW CROSS",
          to: { name: PAGE_NAME_VESTA_SW },
          src: require("@/assets/img/cars/vesta-sw/preview/mobile/preview-sw.webp"),
        },
      ],
    };
  },
  methods: {
    onClick({ name }) {
      if (name === PAGE_NAME_AURA) {
        gtmVestaSedan();
      } else if (name === PAGE_NAME_VESTA_SW) {
        gtmVestaSWCross();
      }
    },
  },
};
</script>

<style lang="scss">
.nav {
  ul {
    @include reset-list;
    margin-top: uniWM(16);
    display: flex;
    // grid-template-columns: 1fr;
    align-items: center;
    justify-content: center;
    gap: uniWM(12);
    text-align: center;
    font-weight: 700;
    font-size: uniWM(16);
    text-transform: uppercase;
    color: var(--colorDark);
    li {
      width: 50%;
      height: 50%;
      a {
        display: block;
        img {
          display: block;
          width: 100%;
        }
      }
    }
  }
}
</style>
