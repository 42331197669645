<template>
  <header class="header">
    <div class="header__item">
      <slot name="header-left"></slot>
    </div>
    <div class="header__item">
      <slot name="header-center"></slot>
    </div>
    <div class="header__item">
      <slot name="header-right"></slot>
      <ButtonChangePlaceholder />
      <SwitcherTheme v-show="isInterior" />
      <ButtonTutorial mode="desktop" />
    </div>
  </header>
</template>

<script>
import SwitcherTheme from "@/components/SwitcherTheme.vue";
import ButtonTutorial from "@/components/ButtonTutorial.vue";
import ButtonChangePlaceholder from "./ButtonChangePlaceholder.vue";

export default {
  name: "HeaderComponent",
  components: { SwitcherTheme, ButtonTutorial, ButtonChangePlaceholder },
  computed: {
    isInterior() {
      const { mode } = this.$route.query;
      return mode === "interior";
    },
  },
};
</script>

<style scoped lang="scss">
.header {
  position: absolute;
  top: 80px; // компенсация для хедера
  left: 0;
  width: 100%;
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  pointer-events: none;
  z-index: 100;
  button {
    pointer-events: all;
  }
  @include mobile {
    display: none;
  }
  &__item {
    margin: uniW(24);
    &:nth-child(1) {
      text-align: left;
    }
    &:nth-child(2) {
      display: grid;
      justify-content: center;
    }
    &:nth-child(3) {
      display: grid;
      grid-auto-flow: column;
      justify-content: flex-end;
      gap: uniW(22);
    }
  }
  &__button {
    pointer-events: auto;
  }
}
</style>
