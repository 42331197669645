<template>
  <div class="swiper-content">
    <swiper
      :modules="[Controller]"
      :slides-per-view="1"
      @swiper="setControlledSwiper"
      @slideChangeTransitionEnd="onSlideChange"
      @slidePrevTransitionEnd="onSlidePrev"
      @slideNextTransitionEnd="onSlideNext"
    >
      <swiper-slide v-for="(item, index) in items" :key="index">
        <div class="swiper-content__item">
          <div class="swiper-content__video">
            <video
              :id="`video_${index}`"
              :src="isMobile ? item.srcMobile : item.src"
              muted
              loop
              playsinline
              autoplay
            ></video>
          </div>
          <div class="swiper-content__title">{{ item.title }}</div>
          <div class="swiper-content__desc" v-html="item.desc"></div>
        </div>
      </swiper-slide>
    </swiper>
  </div>
  <div class="swiper-control">
    <swiper
      :modules="[Controller, Navigation, Pagination]"
      :controller="{ control: controlledSwiper }"
      :slides-per-view="1"
      navigation
      :pagination="{ clickable: true }"
      @swiper="setMainSwiper"
    >
      <swiper-slide v-for="(item, index) in items" :key="index"></swiper-slide>
    </swiper>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { TUTORIAL_LIST } from "@/data/tutorial";
import { Navigation, Pagination, Controller } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import { ref } from "vue";
import { gtmNavigationLeft, gtmNavigationRight } from "@/utils/gtm";

export default {
  name: "TutorialSwiperComponent",
  components: {
    Swiper,
    SwiperSlide,
  },
  computed: {
    ...mapState(["isMobile"]),
  },
  data() {
    return {
      items: TUTORIAL_LIST,
    };
  },
  setup() {
    const mainSwiper = ref(null);
    const controlledSwiper = ref(null);
    const setMainSwiper = (swiper) => {
      mainSwiper.value = swiper;
    };
    const setControlledSwiper = (swiper) => {
      controlledSwiper.value = swiper;
    };
    const onSlideChange = (swiper) => {
      TUTORIAL_LIST.forEach((e, i) => {
        const el = document.getElementById(`video_${i}`);
        if (!el) return;

        if (swiper.realIndex === i) {
          el.play();
        } else {
          el.currentTime = 0;
          el.pause();
        }
      });

      if (mainSwiper.value.realIndex !== swiper.realIndex) {
        mainSwiper.value.slideTo(swiper.realIndex);
      }
    };
    const onSlidePrev = () => {
      gtmNavigationLeft();
    };
    const onSlideNext = () => {
      gtmNavigationRight();
    };
    return {
      Navigation,
      Pagination,
      Controller,
      mainSwiper,
      controlledSwiper,
      setMainSwiper,
      setControlledSwiper,
      onSlideChange,
      onSlidePrev,
      onSlideNext,
    };
  },
};
</script>
