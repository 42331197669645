export function validateStatus(status) {
  return status >= 200 && status < 300;
}

export function isRequired(value, length) {
  if (length) {
    return value && value.length === length
      ? true
      : "Это поле обязательно для заполнения";
  }

  return value ? true : "Это поле обязательно для заполнения";
}

export function isEmail(value) {
  if (!value) {
    return "Это поле обязательно для заполнения";
  }

  const regexEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
  if (!regexEmail.test(value)) {
    return "Это поле должно быть адресом электронной почты";
  }

  return true;
}
