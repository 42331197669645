<template>
  <div ref="tablet" class="tablet">
    <div class="tablet-inner">
      <div class="tablet-body">
        <EnjoyScreens :current="current" @change="onChange" />
      </div>
      <div
        v-if="current && current !== 'loading' && current !== 'yandex'"
        class="tablet-controls"
      >
        <EnjoyControls :current="current" @change="onChange" />
      </div>
    </div>
  </div>
</template>

<script>
import EnjoyScreens from "@/components/EnjoyScreens.vue";
import EnjoyControls from "@/components/EnjoyControls.vue";

export default {
  components: { EnjoyScreens, EnjoyControls },
  name: "EnjoyComponent",
  data() {
    return {
      highlightDuration: 1000,
      current: "",
    };
  },
  mounted() {
    const delayBeforeStart = 800;
    setTimeout(() => {
      this.change("loading");
    }, delayBeforeStart);

    // this.$refs.tablet.addEventListener("mouseup", this.onHighlight);
  },
  methods: {
    // highlightToggle() {
    //   if (this.highlight) return false;

    //   this.highlight = true;
    //   setTimeout(() => {
    //     this.highlight = false;
    //   }, this.highlightDuration);
    // },
    change(value) {
      this.current = value;
    },
    onChange(value) {
      this.change(value);
    },
    // onHighlight(e) {
    //   if (!e.target?.classList?.contains("button")) {
    //     this.highlightToggle();
    //   }
    // },
  },
};
</script>

<style lang="scss" scoped>
.tablet {
  height: calc(100% - 80px);
  aspect-ratio: 850 / 1116;
  position: relative;
  display: grid;
  place-items: center;
  z-index: 1;

  @include mobile {
    height: auto;
    width: calc(100vw - uniWM(32));

    @media (max-height: 570px) {
      transform: translateY(5vh);
    }
  }

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: "";
    background-image: url(@/assets/img/enjoy/frame.webp);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 50% 50%;
    z-index: 1;
    pointer-events: none;
  }

  &::after {
    position: absolute;
    top: 50%;
    left: 50%;
    background: radial-gradient(
      50% 50% at 50% 50%,
      #ffffff 0%,
      transparent 100%
    );
    opacity: 0.2;
    height: 100vh;
    width: 100vh;
    transform: translate3d(-50%, -50%, 0);
    content: "";
    z-index: -1;
    @include mobile {
      content: none;
    }
  }

  &-inner {
    position: relative;
    top: -0.65%;
    left: -0.5%;
    width: calc(100% - 18%);
    height: calc(100% - 17%);
    background-color: #000;
  }
}
</style>
